import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  leagueDisplayContainer: {
    minHeight: 450,
    width: "100%",
  },
  teamSelectContainer: {
    marginBottom: "28px",
    position: "sticky",
    top: "60px",
    width: "100%",
    zIndex: 10,
    background: "linear-gradient(187.51deg, #fffaef 55.82%, #FFFFFF 90.12%)",
    [theme.breakpoints.down("sm")]: {
      background: "linear-gradient(187.51deg, #FFFAEC, 55%, #FFFFFF 90%)",
    },
    [theme.breakpoints.down("xs")]: {
      background: "linear-gradient(187.51deg, #fffaef, 75%, #FFFFFF 90%)",
    },
  },
  teamSelectGroup: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  teamSelectButton: {
    fontFamily: theme.typography.fontFamily,
    textTransform: "none",
    fontWeight: "normal",
    color: theme.palette.secondaryGreenDark.main,
    margin: "10px 10px",
    padding: "12px 24px",
    border: "0px",
    "&.Mui-selected": {
      background: theme.palette.secondary.main,
      color: "#FFFAF5",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 12px",
      margin: "6px 12px",
    },
  },
  divisonNameContainer: {
    padding: "8px 0 16px 0",
  },
  teamMemberRow: {
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "20px",
    },
  },
}));

export default useStyles;
