import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";

import useStyles from "./Navbar-jss";
import Logo from "./logo.png";

import NavbarTabs from "./NavbarTabs";
import LandingPage from "../landingPage/about/LandingPage";
import Initiatives from "../landingPage/initiatives/Initiatives";
import Impact from "../landingPage/impact/Impact";
import Resources from "../landingPage/resources/Resources";
import Mentor from "../portal/MentorDisplay";
import Profile from "../portal/Profile";
import Footer from "./Footer";
import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "../../utils/store";

import { EVENT_CATEGORIES, EVENT_ACTIONS, EVENT_LABELS } from "../../constants";
import { GAnalyticsPostEvent } from "../../utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-label={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function navWidth(isAuthenticated) {
  if (isAuthenticated) {
    return 1400;
  } else {
    return 1050;
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const NAV_TAB_EVENT_CATEGORIES = [
  EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
  EVENT_CATEGORIES.LANDING_PAGE_INITIATIVES,
  EVENT_CATEGORIES.LANDING_PAGE_IMPACT,
  EVENT_CATEGORIES.LANDING_PAGE_RESOURCES,
  // EVENT_CATEGORIES.PORTAL_PROFILE,
];

export default function Navbar() {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [open, setOpen] = React.useState();
  const [width, setWidth] = React.useState(window.innerWidth);

  const userRole = useStore((s) => s.userRole);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const updateWidth = () => {
    setWidth(window.innerWidth);
    handleDrawerClose();
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const { isAuthenticated } = useAuth0();

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.bar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
            >
              <Link
                href="/"
                underline="none"
                onClick={() =>
                  GAnalyticsPostEvent(
                    EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                    EVENT_ACTIONS.CLICK,
                    EVENT_LABELS.TECH_PLUS_ICON
                  )
                }
              >
                <img src={Logo} alt="Logo" />
              </Link>
            </IconButton>
            {width < navWidth(isAuthenticated) ? (
              <div>
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  aria-haspopup="true"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
                  <div onClick={handleDrawerClose}>
                    <NavbarTabs
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      tabOrientation="vertical"
                      tabClassname={classes.tabVertical}
                      tabItemContainerStyle={{ width: "70%" }}
                    />
                  </div>
                </Drawer>
              </div>
            ) : (
              <div>
                <NavbarTabs
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  tabOrientation="horizontal"
                  tabClassname={classes.tabHorizontal}
                />
              </div>
            )}
          </Toolbar>
        </AppBar>
        <TabPanel value={currentTab} index={0}>
          <LandingPage />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Initiatives />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Impact />
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <Resources />
        </TabPanel>
        {isAuthenticated && (
          <TabPanel value={currentTab} index={4}>
            <Mentor />
          </TabPanel>
        )}
        {isAuthenticated && userRole === "Mentor" && (
          <TabPanel value={currentTab} index={5}>
            <Profile />
          </TabPanel>
        )}
      </div>
      <Footer trackingEventCategory={NAV_TAB_EVENT_CATEGORIES[currentTab]} />
    </>
  );
}
