import React from "react";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import useStyles from "./ResourceCard-jss";

import { EVENT_CATEGORIES, EVENT_ACTIONS } from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";

export default function Cards(props) {
  const classes = useStyles();
  return (
    <CardActionArea
      className={classes.cardActionArea}
      href={props.link}
      target="_blank"
      onClick={() =>
        GAnalyticsPostEvent(
          EVENT_CATEGORIES.LANDING_PAGE_RESOURCES,
          EVENT_ACTIONS.CLICK,
          props.cardClickEventTrackingLabel
        )
      }
    >
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={props.img} />
        <Typography gutterBottom variant="h5" className={classes.title}>
          {props.title}
        </Typography>
        <Typography variant="h6" className={classes.desc}>
          {props.desc}
        </Typography>
      </Card>
    </CardActionArea>
  );
}
