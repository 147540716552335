import create from "zustand";

/**
 * Zustand is a shared state management library. The items stored are defined here.
 * 
 * Usage: import `useStore` and define the state you want to access or change:
 *   const userId = useStore((state) => state.userId);
 *   const setuserId = useStore((state) => state.setUserId);
 */

/**
 * The components related to the user's profile image use a few different
 * state objects to cover all cases where image is changed or deleted, and 
 * also to save API calls to retrieve the image when it hasn't changed.
 * 
 * - profileImageDataUrl: used to send the dataUrl of the image taken from 
 * a user's local computer in the ProfileForm component to the outer Profile 
 * component where the api call is made
 * 
 * - imageKey: used to send the S3 file key retrieved in the ProfileForm api 
 * call to the Profile component, where request to the profile_images endpoint
 * is used to retrieve the image file.
 * 
 * - profileImageChanged: used to keep track if a user uploaded a new image or
 * deleted their existing image. The imageKey returned after upload is always the
 * same for a given user, regardless of the file uploaded. 
 * 
 * The userRole (mentor, mentee, admin) and userId objects are user attributes that 
 * are cached during the initial login.
 */

export const useStore = create((set, get) => ({
  profileImageDataUrl: "",
  imageKey: "",
  profileImageChanged: false,
  userRole: "",
  userId: "",
  setProfileImageDataUrl: (dataUrl) => set({ profileImageDataUrl: dataUrl }),
  setImageKey: (key) => set({ imageKey: key }),
  setProfileImageChanged: (changed) => set({ profileImageChanged: changed}),
  setUserRole: (role) => set({ userRole: role }),
  setUserId: (userId) => set({ userId: userId }),
}));
