import studyImg from "../../../assets/images/resources/study.png";
import workImg from "../../../assets/images/resources/work.jpg";
import techImg from "../../../assets/images/resources/101.jpg";
import dearTechImg from "../../../assets/images/resources/dearTech.png";
import internshipsImg from "../../../assets/images/resources/internships.png";
import techCareers from "../../../assets/images/resources/techCareers.png";

import { EVENT_LABELS } from "../../../constants";

const debunkingTechCareers = {
  link: "https://techplusuw.medium.com/debunking-tech-careers-e33ab49782d6",
  title: "Debunking Tech Careers",
  desc: `Do Frontend Engineers push pixels all day? 
  What the heck is DevOps?`,
  img: techCareers,
  cardClickEventTrackingLabel: EVENT_LABELS.ARTICLE_DEBUNKING_TECH_CAREERS,
};

const dearTechPlus = {
  link:
    "https://medium.com/techplusuw/uwaterloo-advice-column-dear-techplus-issue1-9afe1c326805",
  title: "Dear Tech+",
  desc: `Receive guidance from experienced upper-year mentors on all things 
        UWaterloo from hackathons to co-op and even academics.`,
  img: dearTechImg,
  cardClickEventTrackingLabel: EVENT_LABELS.ARTICLE_DEAR_TECH_PLUS,
};

const sixteenWeeksOfInternship = {
  link: "https://www.youtube.com/channel/UCyAUw8sY5NN34v-ad6ym49A",
  title: "16 Weeks of Internships",
  desc: `Gain insight on industries, roles, cities, and more as we share 
        our experiences in the tech industry on YouTube.`,
  img: internshipsImg,
  cardClickEventTrackingLabel:
    EVENT_LABELS.YOUTUBE_SIXTEEN_WEEKS_OF_INTERNSHIPS,
};

const techPlus101 = {
  link: "https://medium.com/@techplusuw/tech-101-q-a-panel-8bbcd15fbf8b",
  title: "Tech+ 101: Kick-Off Q&A Panel",
  desc: `The Tech 101 Q&A Panel was an event aimed at incoming first years 
        to provide some insights into life at University of Waterloo.`,
  img: techImg,
  cardClickEventTrackingLabel: EVENT_LABELS.ARTICLE_KICK_OFF_QNA,
};

const workAbroad = {
  link: "https://www.youtube.com/watch?v=wiXHkmc2SCQ&t=11s",
  title: "Work Abroad: Experience Sharing",
  desc: `Current and past UW students who have interned outside of Canada & USA 
        share their experiences and how they got their opportunities.`,
  img: workImg,
  cardClickEventTrackingLabel: EVENT_LABELS.RECORDING_WORK_ABROAD,
};

const studyAbroad = {
  link:
    "https://medium.com/@techplusuw/study-abroad-experience-sharing-624e3db077d8",
  title: "Study Abroad: Experience Sharing",
  desc: `Learn about the experiences shared by eight current and past 
        student from the University of Waterloo while studying abroad.`,
  img: studyImg,
  cardClickEventTrackingLabel: EVENT_LABELS.ARTICLE_STUDY_ABROAD,
};

export const resourcesCardsLargeScreen = [
  [dearTechPlus, debunkingTechCareers],
  [sixteenWeeksOfInternship, techPlus101],
  [workAbroad, studyAbroad],
];

export const resourcesCardsSmallScreen = [
  dearTechPlus,
  debunkingTechCareers,
  sixteenWeeksOfInternship,
  techPlus101,
  workAbroad,
  studyAbroad,
];
