import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import useStyles from "./Event-jss";

import { EVENT_CATEGORIES, EVENT_ACTIONS } from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";

/**
 * params
 * name: the name of the event
 * text: description
 * links: an @type {Array} of objects with a {link} and a {text} attribute,
 *   each of which is mapped into a button component
 * btnEventTrackingLabel a tracking tag for analytics, @see {EVENT_LABELS} in GoogleAnalytics.js
 */
export default function Event({
  name,
  description,
  links,
  btnEventTrackingLabel,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          className={classes.eventName}
        >
          {name}
        </Typography>
        {description && (
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.info}
          >
            {description}
          </Typography>
        )}
      </div>
      <div className={classes.row}>
        {links &&
          links.map((link, index) => {
            return (
              <Button
                key={index}
                variant="filled"
                color="inherit"
                target="_blank"
                href={link.link}
                className={classes.button}
                onClick={() =>
                  GAnalyticsPostEvent(
                    EVENT_CATEGORIES.LANDING_PAGE_INITIATIVES,
                    EVENT_ACTIONS.CLICK,
                    btnEventTrackingLabel
                  )
                }
              >
                {link.text}
              </Button>
            );
          })}
      </div>
    </div>
  );
}
