import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  nav: {
    position: "relative",
    width: "100%",
    height: "150px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    background: theme.palette.primary.main,
  },
  icons: {
    position: "absolute",
    textAlign: "center",
    height: "34.67px",
    top: "51px",
  },
  bottom: {
    position: "absolute",
    textAlign: "center",
    top: "63.37%",
    bottom: "11.63%",
    color: "white",
  },
}));

export default useStyles;
