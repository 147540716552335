import React from "react";
import UpcomingEventsCard from "../UpcomingEventsCard";
import MockInterview from "../../../assets/images/MockInterview.svg";
import CoffeeChat from "../../../assets/images/CoffeeChat.svg";
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  EVENT_LABELS,
} from "../../../constants";

import useStyles from "./Event-jss";

export default function Event() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="upcoming_events">
      <h1 className={classes.title}>Upcoming Events</h1>
      <div className={classes.eventCardsContainer}>
        <UpcomingEventsCard
          imageUrl={MockInterview}
          eventTitle={"Mentorship Winter 2023"}
          buttonTitle={"Sign-up closed"}
        />
        <UpcomingEventsCard
          imageUrl={CoffeeChat}
          eventTitle={"Coffee Chats"}
          /*eventDate="Winter 2023"*/
          buttonTitle={"Coming Soon"}
          /*signUpLink={"https://forms.gle/JMRu3wGDv4tzdsqUA"}*/
          eventTrackingData={[
            EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
            EVENT_ACTIONS.CLICK,
            EVENT_LABELS.UPCOMING_EVENTS_COFFEE_CHAT_BTN,
          ]}
        />
      </div>
    </div>
  );
}
