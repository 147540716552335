import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Event from "../Event";
import EventsSection from "../EventsSection";

import useStyles from "./PastEvents-jss";

import { EVENT_CATEGORIES, EVENT_ACTIONS } from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";
import {
  PAST_EVENT_TAB_TRACKING_LABELS,
  tabNames,
  eventsSections,
} from "./config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function PastEvents() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    GAnalyticsPostEvent(
      EVENT_CATEGORIES.LANDING_PAGE_INITIATIVES,
      EVENT_ACTIONS.CLICK,
      PAST_EVENT_TAB_TRACKING_LABELS[newValue]
    );

    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    GAnalyticsPostEvent(
      EVENT_CATEGORIES.LANDING_PAGE_INITIATIVES,
      EVENT_ACTIONS.SWIPE,
      PAST_EVENT_TAB_TRACKING_LABELS[index]
    );

    setValue(index);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Programs & Events</Typography>
      <Typography className={classes.header}>Our Past Events</Typography>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          aria-label="scrollable auto tabs example"
          scrollButtons="auto"
        >
          {tabNames.map((label, index) => (
            <Tab label={label} {...a11yProps(index)} className={classes.tab} />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {eventsSections.map((eventSection, index) => (
          <TabPanel
            value={value}
            index={index}
            dir={theme.direction}
            className={classes.panel}
          >
            <EventsSection
              events={eventSection.map((event, index) => (
                <Event
                  name={event.eventName}
                  description={event.description}
                  links={event.links}
                  btnEventTrackingLabel={event.btnClickEventTrackingLabel}
                />
              ))}
            />
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
}
