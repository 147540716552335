import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import TeamMemberDisplay from "./TeamMemberDisplay";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import useStyles from "./TeamDisplay-jss";

export default function TeamDisplay({ league }) {
  const classes = useStyles();
  const [team, setTeam] = useState(league.find(({ name }) => name === "Core"));
  const updateTeam = (e, selectedTeam) => {
    const newTeam = league.find(({ name }) => name === selectedTeam);
    if (newTeam && newTeam.name !== team.name) {
      setTeam(league.find(({ name }) => name === selectedTeam));
    }
  };

  return (
    <div className={classes.leagueDisplayContainer}>
      <Grid container justify="center" className={classes.teamSelectContainer}>
        <ToggleButtonGroup
          justify="center"
          value={team.name}
          className={classes.teamSelectGroup}
          color="secondary"
          exclusive
          onChange={updateTeam}
          container
          md={12}
          xs={10}
        >
          {league.map(({ name }, index) => (
            <ToggleButton
              key={`${name}${index}`}
              value={name}
              className={classes.teamSelectButton}
              variant="text"
            >
              <Typography variant="h6" component={"span"}>
                {name}
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>

      <Grid container justify="center">
        {team.divisions.map(({ divisionName, divisionMembers }) => (
          <>
            {divisionName !== "" && (
              <Grid
                item
                justify="center"
                align="center"
                className={classes.divisonNameContainer}
              >
                <Typography variant="h5" align="center" fontWeight="bold">
                  {divisionName}
                </Typography>
              </Grid>
            )}
            <Grid
              sx={{ xs: { overflow: "scroll" } }}
              container
              item
              justify="center"
              className={classes.teamMemberRow}
            >
              {divisionMembers.map((member) => (
                <TeamMemberDisplay
                  key={member.name}
                  name={member.name}
                  role={member.role}
                  photoUrl={member.photoUrl}
                  linkedInUrl={member.linkedInUrl}
                />
              ))}
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  );
}
