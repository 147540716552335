import React, { useEffect } from "react";
import FeatureFlagContext from "src/FeatureFlagContext";
import Navbar from "src/components/navigation/Navbar";
import { Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import flagsmith from "flagsmith";

function usePageViews() {
  const host = window.location.hostname;
  useEffect(() => {
    if (host !== "localhost") {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {
        titleCase: false,
      });
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [host]);
}

function App() {
  const [flagsmithFFs, setFlagsmithFFs] = React.useState({
    loading: true,
    allFeatureFlags: undefined,
  });


  React.useEffect(() => {
    flagsmith.init({
      environmentID: process.env.REACT_APP_FLAGSMITH_ENV_KEY,
      onChange: () => {
        // Occurs whenever flags are changed
        setFlagsmithFFs({ loading: false, allFeatureFlags: flagsmith.flags });
      },
    });
  }, []);

  usePageViews();
  return (
    <Switch>
      <FeatureFlagContext.Provider value={flagsmithFFs}>
        <Route exact path="/" component={Navbar} />
      </FeatureFlagContext.Provider>
    </Switch>
  );
}

export default App;
