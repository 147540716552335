/**
 * These are the Google Analytics events, labels, and categories used for tracking 
 */

export const EVENT_CATEGORIES = {
  LANDING_PAGE_ABOUT: "landing_page_about",
  LANDING_PAGE_INITIATIVES: "landing_page_initiatives",
  LANDING_PAGE_IMPACT: "landing_page_impact",
  LANDING_PAGE_RESOURCES: "landing_page_resources",
  PORTAL_MENTORS: "portal_mentors",
  PORTAL_PROFILE: "portal_profile",
};

export const EVENT_ACTIONS = {
  CLICK: "click",
  TYPE: "type",
  SWIPE: "swipe",
};

export const EVENT_LABELS = {
  TECH_PLUS_ICON: "tech_plus_icon",
  TAB_NAVIGATION: "tab_navigation",

  // About - Banner labels
  BANNER_TWITTER_ICON: "banner_twitter_icon",
  BANNER_MAIL_ICON: "banner_mail_icon",
  BANNER_FACEBOOK_ICON: "banner_facebook_icon",
  BANNER_INSTAGRAM_ICON: "banner_instagram_icon",
  BANNER_UPCOMING_EVENTS_BTN: "banner_upcoming_events_btn",
  BANNER_MAILING_LIST_BTN: "banner_mailing_list_btn",

  // About - Upcoming events
  UPCOMING_EVENTS_COFFEE_CHAT_BTN: "upcoming_events_coffee_chat_btn",

  // About - Meet our team
  MEET_OUR_TEAM_LINKEDIN_ICON: "meet_our_team_linkedin_icon",

  // About - Keep in touch
  KEEP_IN_TOUCH_FULL_NAME_INPUT: "keep_in_touch_full_name_input",
  KEEP_IN_TOUCH_EMAIL_INPUT: "keep_in_touch_email_input",
  KEEP_IN_TOUCH_SUBSCRIBE_BTN: "keep_in_touch_subscribe_btn",

  // Initiatives - Past events
  PAST_EVENTS_MENTORSHIP_PRGM: "past_events_mentorship_prgm",
  PAST_EVENTS_EXP_SHARING: "past_events_exp_sharing",
  PAST_EVENTS_FIRST_YEAR_RESOURCES: "past_events_first_year_resources",
  PAST_EVENTS_RESUME_INTERVIEW_PREP: "past_events_resume_interview_prep",

  PAST_EVENTS_WORK_ABROAD_RECAP_BTN: "past_events_work_abroad_recap_btn",
  PAST_EVENTS_STUDY_ABROAD_RECAP_BTN: "past_events_study_abroad_recap_btn",
  PAST_EVENTS_101_WORKSHOP_BTN: "past_events_101_workshop_btn",
  PAST_EVENTS_AI_ML_WORKSHOP_BTN: "past_events_ai_ml_workshop_btn",
  PAST_EVENTS_FINANCIAL_LITERACY_WORKSHOP_BTN:
    "past_events_financial_literacy_workshop_btn",
  PAST_EVENTS_SUSTAINABLE_LEADERS_IN_TECH_BTN:
    "past_events_sustainable_leaders_in_tech_btn",
  PAST_EVENTS_BREAKING_INTO_TECH_DAVID_LI:
    "past_events_breaking_into_tech_david_li_btn",

  // Initiatives - Our programs
  COFFEE_CHAT_GUIDE_BTN: "coffee_chat_guide_btn",

  // Impact - Growth stories
  GROWTH_STORIES_TAB: "growth_stories_tab",

  // Resources - articles
  ARTICLE_DEAR_TECH_PLUS: "article_dear_tech_plus",
  ARTICLE_KICK_OFF_QNA: "article_kick_off_qna",
  RECORDING_WORK_ABROAD: "recording_work_abroad",
  ARTICLE_STUDY_ABROAD: "article_study_abroad",
  YOUTUBE_SIXTEEN_WEEKS_OF_INTERNSHIPS: "youtube_sixteen_weeks_of_internships",

  // Resources - D&I
  DNI_LINK_CANADIAN_WORKPLACES: "dni_link_canadian_workplaces",
  DNI_LINK_LGBTQ_SHOULD_I_USE: "dni_link_lgbtq_should_i_use",
  DNI_LINK_MINORITIES_IN_TECH: "dni_link_minorities_in_tech",
  DNI_LINK_FIVE_YEARS_REPORT: "dni_link_five_years_report",

  // Resources - Get involved
  GET_INVOLVED_LINK: "get_involved_link",

  // Footer
  FOOTER_LINKEDIN_ICON: "footer_linkedin_icon",
  FOOTER_TWITTER_ICON: "footer_twitter_icon",
  FOOTER_MAIL_ICON: "footer_mail_icon",
  FOOTER_FACEBOOK_ICON: "footer_facebook_icon",
  FOOTER_INSTAGRAM_ICON: "footer_instagram_icon",
};
