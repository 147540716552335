import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  eventSection: {
    maxHeight: "180px",
    overflowY: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "block",
      width: "3px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#A8A9A8",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#75A066",
    },
  },
}));

export default useStyles;
