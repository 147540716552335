import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: theme.spacing(24),
    maxWidth: 600,
    padding: "0",
    margin: "5%",
    backgroundColor: theme.palette.primary.main,
  },
  wrapper: {
    "&::after": {
      content: '""',
      position: "absolute",
      border: `4px solid ${theme.palette.secondaryYellowLight.main}`,
      width: "100%",
      height: "100%",
      left: "-6px",
      top: "-6px",
      boxSizing: "border-box",
    },
  },
  media: {
    height: 140,
    width: "auto",
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    margin: "4% auto 4% auto",
  },
  plant: {
    zIndex: 1,
    position: "absolute",
    margin: "0 8px 8px 0",
    left: 0,
    width: "25%",
    height: "25%",
    maxWidth: theme.spacing(16),
    maxHeight: theme.spacing(16),
  },
  grayPlant: {
    position: "absolute",
    bottom: 0,
    right: 0,
    margin: "0",
  },
  content: {
    textAlign: "center",
  },
  title: {
    height: "4vh",
    fontWeight: "600",
    color: theme.palette.secondaryYellowLight.main,
    padding: "0 6% 0 6%",
    marginBottom: "12px",
  },
  program: {
    height: "8vh",
    color: "#FFFAF5",
    padding: "0 2% 3% 2%",
    marginBottom: "24px",
  },
  cardContentContainer: {
    padding: "3%"
  },
  cardAvatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  cardName: {
    padding: "auto 3%",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "42px",
  },
  cardPronouns: {
    color: "#6C9A5C",
    fontSize: "22px",
    fontWeight: "600",
    lineHeight: "33px", 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", 
    paddingLeft: "3%"
  },
  cardPronounsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  cardProgram: {
    padding: "8px 0 0 0",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "32px",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: "0",
  },
  cardTimezone: {
    padding: "auto",
    fontWeight: "500",
    fontSize: "20px",
    color: "rgba(0, 0, 0, 0.4)",
  },
  cardSectionTitle: {
    fontWeight: "600",
    fontSize: "28px",
    lineHeight: "3px",
    color: "#020B2C",
    display: "inline-block",
    background: "rgba(236, 178, 46, 0.3)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "20% 100%",
    margin: "3% 0", 
    paddingLeft: "1%",
    paddingRight: "1%",
    paddingBottom: "2%"
  },
  cardSectionContainer: {
    paddingBottom: "3%"
  },
  basicInfoContainer: {
    padding: "4% 0 3%"
  },
  basicInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  chip: {
    margin: 4,
    borderRadius: 5,
    borderColor: "#020B2C",
  },
  leaf: {
    position: "absolute",
    top: "2%",
    right: 0,
  }, 
}));

export default useStyles;
