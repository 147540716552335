import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import useStyles from "./Testimonial-jss";

export default function Testimonial({ participantName, testimonial, picture }) {
  const classes = useStyles();

  return (
    <div>
      <Avatar src={picture} className={classes.picture} />
      <Card className={classes.card}>
        <Typography className={classes.participantName}>
          {participantName}
        </Typography>
        <Typography className={classes.testimonials}>{testimonial}</Typography>
      </Card>
    </div>
  );
}
