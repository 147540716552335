import React from "react";
import Cards from "./ResourceCard";
import useStyles from "./ResourcesDisplay-jss";
import Carousel from "react-material-ui-carousel";

import { resourcesCardsLargeScreen, resourcesCardsSmallScreen } from "./data";

export default function ResourcesDisplay() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.titleSection}>
        <h1 className={classes.title}>Resources</h1>
        <p className={classes.desc}>
          Check out our Medium articles and recordings from past events!
        </p>
      </div>
      <div className={classes.displayContainer}>
        <Carousel
          autoPlay={false}
          animation="fade"
          indicators={true}
          timeout={500}
          navButtonsAlwaysVisible={true}
          fullHeightHover={false}
          className={classes.carouselContainer}
        >
          {window.innerWidth > 1040
            ? resourcesCardsLargeScreen.map((page, index) => (
                <div className={classes.cardContainer} index={index}>
                  {page.map((card, index) => (
                    <Cards
                      key={index}
                      link={card.link}
                      title={card.title}
                      desc={card.desc}
                      img={card.img}
                      cardClickEventTrackingLabel={
                        card.cardClickEventTrackingLabel
                      }
                    />
                  ))}
                </div>
              ))
            : resourcesCardsSmallScreen.map((card, index) => (
                <Cards
                  key={index}
                  link={card.link}
                  title={card.title}
                  desc={card.desc}
                  img={card.img}
                  cardClickEventTrackingLabel={card.cardClickEventTrackingLabel}
                />
              ))}
        </Carousel>
      </div>
    </div>
  );
}
