import Content from "./Content";
import Event from "./Event";
import KeepInTouch from "../KeepInTouch";
import Profile from "./Profile";
import React from "react";

export default function LandingPage() {
  return (
    <div>
      <Content />
      <Event />
      <Profile />
      <KeepInTouch />
    </div>
  );
}
