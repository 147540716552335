import React, { useEffect, useState, useCallback } from "react";
import useStyles from "./MentorDisplayCard-jss";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Link from "@material-ui/core/Link";
import { useAuth0 } from "@auth0/auth0-react";

import classNames from "classnames";
import LeafImg from "../../../assets/images/portal/profileCard/mentorprofilecardleaf.svg";

import Smile from "../../../assets/images/portal/mentorProfile/smile.png";

export default function MentorDisplayCard(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();
  const { getAccessTokenSilently } = useAuth0();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getProfileImage = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!props.imageKey) {
      return;
    }
    const response = await fetch(
      `${
        process.env.REACT_APP_TECHPLUS_API
      }/profile_images/?image_key=${encodeURIComponent(props.imageKey)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const blob = await response.blob();
    setImage(URL.createObjectURL(blob));
  }, [props.imageKey, getAccessTokenSilently]);

  useEffect(() => {
    getProfileImage();
  }, [getProfileImage]);

  return (
    <div>
      <div className={classes.root}>
        <CardActionArea onClick={handleClickOpen} className={classes.wrapper}>
          <Box>
            <img
              src={require("../../../assets/images/portal/mentorProfile/plant.png")}
              className={classes.plant}
              alt=""
            />
          </Box>
          <CardContent className={classes.content}>
            <Avatar src={image || Smile} className={classes.avatar} />
            <Typography
              gutterBottom
              variant="body1"
              component="h2"
              className={classes.title}
            >
              {props.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.program}
            >
              <Box>{`${props.term} ${props.program}`}</Box>
            </Typography>
          </CardContent>
          <img
            src={require("../../../assets/images/portal/mentorProfile/gray-plant.png")}
            className={classes.grayPlant}
            alt=""
          />
        </CardActionArea>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        scroll="body"
      >
        <img src={LeafImg} alt="leaf" className={classes.leaf} />
        <DialogContent>
          <div className={classes.cardContentContainer}>
            <Grid container spacing={2} className={classes.basicInfoContainer}>
              <Grid item xs={3}>
                <Avatar src={image || Smile} className={classes.cardAvatar} />
              </Grid>
              <Grid item xs={9} className={classes.basicInfo}>
                <Grid container spacing={1}>
                  <Typography className={classes.cardName}>
                    {props.name}
                  </Typography>
                  {props.pronouns ? (
                    <Typography className={classes.cardPronouns}>
                      ({props.pronouns})
                    </Typography>
                  ) : (
                    <div />
                  )}
                </Grid>

                <Typography gutterBottom className={classes.cardProgram}>
                  {props.term}{props.term && props.program ? "," : ""} {props.program}
                </Typography>

                <Typography className={classes.cardTimezone}>
                  {props.timeZone}
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Link
                      href={`mailto:${props.email}`}
                      aria-label="Mentor Email"
                      target="_blank"
                    >
                      <EmailIcon
                        style={{ color: "#6C9A5C" }}
                        fontSize="large"
                      />
                    </Link>
                  </Grid>

                  <Grid item xs={1}>
                    {props.linkedin && (
                      <Link
                        href={`https://www.linkedin.com/in/${props.linkedin}/`}
                        aria-label="Mentor Linkedin"
                        target="_blank"
                      >
                        <LinkedInIcon
                          style={{ color: "#6C9A5C" }}
                          fontSize="large"
                        />
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {props.areasOfExpertise && props.areasOfExpertise.length > 0 && (
              <div className={classes.cardSectionContainer}>
                <Typography className={classNames(classes.cardSectionTitle)}>
                  Areas of Expertise
                </Typography>
                <DialogContentText>
                  {props.areasOfExpertise.map((e) => (
                    <Chip
                      key={e}
                      label={`${e}`}
                      className={classes.chip}
                      variant="outlined"
                    />
                  ))}
                </DialogContentText>
              </div>
            )}

            <div className={classes.cardSectionContainer}>
              <Typography className={classNames(classes.cardSectionTitle)}>
                You can ask me about:
              </Typography>
              <DialogContentText>{props.askMeAbout}</DialogContentText>
            </div>

            <div className={classes.cardSectionContainer}>
              <Typography className={classNames(classes.cardSectionTitle)}>
                In my freetime I enjoy:
              </Typography>
              <DialogContentText>{props.hobbies}</DialogContentText>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
