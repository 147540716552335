import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import useStyles from "./BannerCard-jss";

export default function BannerCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <div className={props.boxStyle}>
        <div className={classes.content}>
          <Typography variant="h3" className={classes.bigText}>
            {props.count}
          </Typography>
          <Typography className={classes.smallText}>{props.desc}</Typography>
        </div>
      </div>
      <CardMedia className={classes.image} image={props.img} />
    </Card>
  );
}
