import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5% 10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: `${theme.palette.primary.main}1A`,
    gap: "20px",
  },
  title: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "54px",
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  desc: {
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "27px",
    textAlign: "center",
    color: theme.palette.primary.main,
    maxWidth: "650px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  inputField: {
    width: "100%",
    maxWidth: "543px",
    background: "#FFFFFF",
    border: `1px solid ${theme.palette.primary.main} !important`,
    marginBottom: "30px !important",
    [`& fieldset`]: {
      border: "none",
      borderRadius: 0,
    },
  },
  button: {
    fontSize: "28px",
    lineHeight: "42px",
    fontWeight: "500",
    color: "#FFFFFF",
    background: theme.palette.primary.main,
    width: "100%",
    maxWidth: "217px",
    height: "54px",
    textTransform: "none",
    borderRadius: "0",
    "&:hover": {
      background: theme.palette.secondaryGreenDark.main,
    },
    "&:disabled": {
      background: theme.palette.primary.main,
    },
  },
  loader: {
    color: "white !important",
  },
  subscriptionAlert: {
    top: "80px !important",
  },
}));

export default useStyles;
