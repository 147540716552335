import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "sans-serif",
  },
  title: {
    fontWeight: "600",
    fontSize: "48px",
    width: "auto",
    color: "#000000",
    display: "inline-block",
    padding: "0 8px 0 8px",
    background: `linear-gradient(0deg, ${fade(
      "#62AADF",
      "0.51"
    )} 50%, #FFFFFF 50%)`,
  },
  titleContainer: {
    textAlign: "center",
    lineHeight: "72px",
    padding: "2%",
    width: "auto",
  },
  container: {
    margin: "4% 1%",
    justifyContent: "center",
    textAlign: "center",
    width: "auto",
  },
  mentors: {
    justifyContent: "center",
    textAlign: "center",
  },
}));

export default useStyles;
