import { createMuiTheme } from "@material-ui/core/styles";

/**
 * This file defines the theme colors and fonts. 
 * The specified styles will overwrite the default UI choices from Material UI. 
 * Example usage in styling:
    textStyle: {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
    },
*/

const { palette } = createMuiTheme();
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#020B2C",
    },
    secondary: {
      main: "#6C9A5C",
      light: "#8BC677",
    },
    secondaryYellowLight: palette.augmentColor({
      main: "#FFDC84",
    }),
    secondaryYellowMedium: palette.augmentColor({
      main: "#F9CD34",
    }),
    secondaryYellowDark: palette.augmentColor({
      main: "#ECB22E",
    }),
    secondaryGreenLight: palette.augmentColor({
      main: "#8BC677",
    }),
    secondaryGreenDark: palette.augmentColor({
      main: "#6C9A5C",
    }),
  },
  typography: {
    fontFamily: `"Poppins","Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

export default theme;
