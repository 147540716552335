import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  member: {
    height: "335px",
    textAlign: "center",
    boxSizing: "border-box",
    "&:last-child": {
      marginBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      "&:nth-last-child(2)": {
        marginBottom: "0",
      },
    },
  },
  avatar: {
    width: "174px",
    height: "167px",
    margin: "auto",
    marginBottom: "15px",
  },
  name: {
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "36px",
  },
  role: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "26px",
    color: theme.palette.secondaryGreenDark.main,
  },
}));

export default useStyles;
