import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Typography,
  Box,
  TextField,
  Grid,
  Chip,
} from "@material-ui/core";
import useStyles, { customStyles } from "./ProfileForm-jss";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { Alert } from "@material-ui/lab";
import Select from "react-select";
import classNames from "classnames";
import theme from "src/components/theme";
import {
  expertiseList,
  programsList,
  termsList,
  timezonesList,
  STATUS_CODE_200,
  getProgram,
  getTerm,
  getExpertise,
  getTimezone,
} from "src/constants";
import {
  dataURLtoFile,
  jsonKeysToCamelCase,
  jsonKeysToSnakeCase,
} from "src/utils/formatting";
import { useStore } from "../../../utils/store";

const ALERT_TYPES = {
  UPDATE_SUCCESS: "success",
  ERROR: "error",
};

const ALERT_DESC_MAP = {
  [ALERT_TYPES.UPDATE_SUCCESS]: "Profile succesfully saved!",
  [ALERT_TYPES.ERROR]: "Error saving profile, please try again.",
};

export default function ProfileForm(props) {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const classes = useStyles();
  const [alertType, setAlertType] = useState("");
  const [userData, setUserData] = useState();
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState("");
  const profileImageDataUrl = useStore((state) => state.profileImageDataUrl);
  const setProfileImageDataUrl = useStore(
    (state) => state.setProfileImageDataUrl
  );
  const imageKey = useStore((state) => state.imageKey);
  const setImageKey = useStore((state) => state.setImageKey);
  const profileImageChanged = useStore((state) => state.profileImageChanged);

  const multiSelect = [
    {
      id: "areasOfExpertise",
      heading: "Areas of Expertise",
      isMulti: true,
      required: true,
      options: expertiseList,
      prefill: userData?.areasOfExpertise?.map((expertise) =>
        getExpertise(expertise)
      ),
      error: errors?.areasOfExpertise,
    },
  ];

  const longAnswersFields = [
    {
      id: "askMeAbout",
      heading: "You can ask me about",
      required: true,
      prefill: userData?.askMeAbout,
      error: errors?.askMeAbout,
    },
    {
      id: "hobbies",
      heading: "In my free time I enjoy",
      required: true,
      prefill: userData?.hobbies,
      error: errors?.hobbies,
    },
  ];

  const shortAnswersFields = [
    {
      id: "displayName",
      heading: "Display Name",
      required: true,
      prefill: userData?.displayName,
      error: errors?.displayName,
    },
    {
      id: "pronouns",
      heading: "Pronouns",
      required: true,
      prefill: userData?.pronouns,
      error: errors?.pronouns,
    },
    {
      id: "email",
      heading: "Email",
      required: true,
      prefill: userData?.email,
      error: errors?.email,
    },
    {
      id: "linkedin",
      heading: "LinkedIn Username",
      required: false,
      prefill: userData?.linkedin,
      error: errors?.linkedin,
    },
  ];

  const dropDowns = [
    {
      id: "academicProgram",
      heading: "Program",
      required: true,
      value: "",
      selectedOption: "",
      prefill: getProgram(userData?.academicProgram),
      options: programsList,
      placeholder: "Select a program",
      error: errors?.academicProgram,
    },
    {
      id: "termLevel",
      heading: "Term",
      required: true,
      prefill: getTerm(userData?.termLevel),
      options: termsList,
      placeholder: "Select a term",
      error: errors?.termLevel,
    },
    {
      id: "timeZone",
      heading: "Timezone",
      required: false,
      prefill: getTimezone(userData?.timeZone),
      options: timezonesList,
      placeholder: "Select a timezone",
      error: errors?.timeZone,
    },
  ];

  const getUserId = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const member = await fetch(
      `${
        process.env.REACT_APP_TECHPLUS_API
      }/members/?email=${encodeURIComponent(user.email)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const memberResponse = await member.json();
    if (memberResponse.isRegisteredUser) {
      setUserId(memberResponse.userId);
      return memberResponse.userId.toString();
    } else {
      setAlertType(ALERT_TYPES.ERROR);
      return;
    }
  }, [getAccessTokenSilently, user.email]);

  const fetchUserData = useCallback(async () => {
    if (!isAuthenticated || !user) {
      loginWithRedirect();
    } else {
      setProfileImageDataUrl("");
      const token = await getAccessTokenSilently();

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      };
      const id = userId || (await getUserId());
      try {
        const mentorRequest = await fetch(
          `${process.env.REACT_APP_TECHPLUS_API}/mentors/${id}`,
          options
        );
        const data = await mentorRequest.json();

        if (mentorRequest.status === STATUS_CODE_200) {
          setImageKey(data.image_key);
          setUserData(jsonKeysToCamelCase(data));
        } else {
          setAlertType(ALERT_TYPES.ERROR);
        }
      } catch (error) {
        setAlertType(ALERT_TYPES.ERROR);
      }
    }
  }, [
    userId,
    user,
    getAccessTokenSilently,
    loginWithRedirect,
    getUserId,
    isAuthenticated,
    setImageKey,
    setProfileImageDataUrl,
  ]);

  const updateProfileImage = async () => {
    if (!isAuthenticated || !user) {
      loginWithRedirect();
    } else {
      const token = await getAccessTokenSilently();

      const requestBody = new FormData();
      var imageData = profileImageDataUrl
        ? dataURLtoFile(profileImageDataUrl, "profile_picture")
        : null;

      if (imageData) {
        requestBody.append("file", imageData);
      }

      const profileImageRequest = await fetch(
        `${process.env.REACT_APP_TECHPLUS_API}/profile_images/${userId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: requestBody,
        }
      );
      const response = await profileImageRequest.json();
      if (profileImageRequest.status !== STATUS_CODE_200) {
        setAlertType(ALERT_TYPES.ERROR);
        return;
      } else {
        return response.image_key;
      }
    }
  };

  const updateUserData = async () => {
    if (!isAuthenticated || !user) {
      loginWithRedirect();
    } else {
      setAlertType("");
      if (!userId || userId === "") {
        getUserId();
      }

      const token = await getAccessTokenSilently();
      let newImageKey = imageKey;

      try {
        if (profileImageChanged) {
          newImageKey = await updateProfileImage();
        }
        const memberRequest = await fetch(
          `${process.env.REACT_APP_TECHPLUS_API}/members/${userId}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
            body: JSON.stringify(
              jsonKeysToSnakeCase({ ...userData, imageKey: newImageKey })
            ),
          }
        );

        if (memberRequest.status !== STATUS_CODE_200) {
          setAlertType(ALERT_TYPES.ERROR);
          return;
        }

        const mentorRequest = await fetch(
          `${process.env.REACT_APP_TECHPLUS_API}/mentors/${userId}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
            body: JSON.stringify(jsonKeysToSnakeCase(userData)),
          }
        );

        if (mentorRequest.status === STATUS_CODE_200) {
          setAlertType(ALERT_TYPES.UPDATE_SUCCESS);
        } else {
          setAlertType(ALERT_TYPES.ERROR);
        }
      } catch (error) {
        setAlertType(ALERT_TYPES.ERROR);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [userId, fetchUserData]);

  function toggleReadEdit() {
    props.onChange();
    updateUserData();
  }

  const variantOutline = props.readMode
    ? { variant: "standard" }
    : { variant: "outlined" };

  const handleClose = () => {
    setAlertType("");
  };

  const handleUserTextInput = (event) => {
    if (event.target.value.length === 0 && event.target.required) {
      setErrors({ ...errors, [event.target.id]: "Required" });
    } else {
      setErrors({ ...errors, [event.target.id]: "" });
      setUserData({ ...userData, [event.target.id]: event.target.value });
    }
  };

  const handleUserDropdownInput = (newValue, actionMeta) => {
    if (actionMeta.action === "clear") {
      setDropdownData(actionMeta.removedValues[0], null);
    } else {
      setDropdownData(newValue, newValue.value);
    }
  };

  const setDropdownData = (selection, newValue) => {
    if (termsList.includes(selection)) {
      setUserData({ ...userData, termLevel: newValue });
    }
    if (programsList.includes(selection)) {
      setUserData({ ...userData, academicProgram: newValue });
    }
    if (timezonesList.includes(selection)) {
      setUserData({ ...userData, timeZone: newValue });
    }
  };

  const handleMultiSelectChange = (selectedOptions) => {
    let newExpertises = selectedOptions.reduce((filtered, option) => {
      if (expertiseList.includes(option)) {
        filtered.push(option.value);
      }
      return filtered;
    }, []);
    setUserData({ ...userData, areasOfExpertise: newExpertises });

    if (newExpertises.length === 0) {
      setErrors({ ...errors, areasOfExpertise: "Required" });
    } else {
      setErrors({ ...errors, areasOfExpertise: "" });
    }
  };

  return userData && userData.firstName ? (
    <FormControl className={classes.form} component="fieldset">
      {alertType !== "" && (
        <Alert onClose={handleClose} severity={alertType}>
          {ALERT_DESC_MAP[alertType]}
        </Alert>
      )}
      <Typography
        className={classNames(
          classes.mentorProfileTitle,
          classes.portalTitleStyles
        )}
      >
        Mentor Profile
      </Typography>
      <FormGroup aria-label="position" row>
        <Grid container spacing={3} className={classes.bottomMargin}>
          {shortAnswersFields.map((field) => (
            <Grid item xs={12} sm={6}>
              <Typography className={classes.name}>
                {field.heading} {field.required && " *"}
              </Typography>
              <TextField
                required={field.required}
                id={field.id}
                {...variantOutline}
                InputProps={{
                  disableUnderline: true,
                  className: classes.textField,
                  readOnly: props.readMode,
                }}
                defaultValue={props.readMode && !field.prefill ? "N/A" : field.prefill}
                onChange={handleUserTextInput}
                disabled={!props.readMode && field.id === "email"}
                error={
                  errors[field.id] !== undefined && errors[field.id] !== ""
                }
                helperText={field.error}
              />
            </Grid>
          ))}
          {dropDowns.map((field) => (
            <Grid item xs={12} sm={6}>
              <Typography className={classes.name}>
                {field.heading} {field.required && " *"}
              </Typography>
              <div style={{ width: "230px" }}>
                {props.readMode ? (
                  field.prefill ? (
                    <Typography>{field.prefill.label}</Typography>
                  ) : (
                    <Typography>N/A</Typography>
                  )
                ) : (
                  <Select
                    styles={customStyles}
                    options={field.options}
                    placeholder={field.placeholder}
                    required={field.required}
                    isClearable={!field.required}
                    onChange={handleUserDropdownInput}
                    defaultValue={field.prefill}
                  />
                )}
              </div>
            </Grid>
          ))}
        </Grid>
        <Typography
          className={classNames(
            classes.funFactsTitle,
            classes.portalTitleStyles
          )}
        >
          Fun Facts & Expertise
        </Typography>
        {multiSelect.map((field) => (
          <Box className={classes.longBox}>
            <Typography className={classes.name}>
              {field.heading} {field.required && " *"}
            </Typography>
            {props.readMode ? (
              <Typography>
                <div className={classes.chips}>
                  {field.prefill?.map((expertise) => (
                    <Chip
                      key={expertise.value}
                      label={expertise.label}
                      className={classes.chip}
                      variant="outlined"
                    />
                  ))}
                </div>
              </Typography>
            ) : (
              <>
                <Select
                  styles={customStyles}
                  isMulti={field.isMulti}
                  options={field.options}
                  onChange={handleMultiSelectChange}
                  defaultValue={field.prefill}
                  closeMenuOnSelect={false}
                  required={field.required}
                  error={
                    errors[field.id] !== undefined && errors[field.id] !== ""
                  }
                />
                {errors[field.id] !== undefined && errors[field.id] !== "" ? (
                  <Typography className={classes.errorText}>
                    Required
                  </Typography>
                ) : null}
              </>
            )}
          </Box>
        ))}
        {longAnswersFields.map((field) => (
          <Box className={classes.longBox}>
            <Typography className={classes.name}>
              {field.heading + " *"}
            </Typography>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              id={field.id}
              {...variantOutline}
              InputProps={{
                disableUnderline: true,
                readOnly: props.readMode,
                className: classes.longInput,
              }}
              defaultValue={field.prefill}
              onChange={handleUserTextInput}
              required={field.required}
              error={errors[field.id] !== undefined && errors[field.id] !== ""}
              helperText={field.error}
            />
          </Box>
        ))}
        {!props.readMode && (
          <Grid container justify="flex-end">
            <Button
              className={classes.button}
              variant="contained"
              color={theme.palette.secondaryGreenDark.main}
              onClick={toggleReadEdit}
              disabled={!Object.values(errors).every((error) => error === "")}
            >
              Save Changes
            </Button>
          </Grid>
        )}
      </FormGroup>
    </FormControl>
  ) : (
    <Typography>Loading...</Typography>
  );
}
