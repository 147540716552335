import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Testimonial from "../Testimonial";
import Alice from "../../../assets/images/people/AliceL.png";
import Prithika from "../../../assets/images/people/Prithika.png";
import Linna from "../../../assets/images/people/Linna.png";
import Rayan from "../../../assets/images/people/Rayan.png";
import Mapendo from "../../../assets/images/people/mapendo.png";

import useStyles from "./Testimonials-jss";

export default function Testimonials() {
  const classes = useStyles();

  const testimonials = [
    {
      participantName: "Rayan Roy",
      testimonial:
        '"I enjoyed learning more about transitioning into software development and data science. I enjoyed learning more about transitioning into software development and data science. They were really helpful in guiding me on what projects to do, how to approach side projects, as well as Cali culture."',
      picture: Rayan,
    },
    {
      participantName: "Alice Li",
      testimonial:
        '"My mentor was super supportive of navigating me through challenges during my first co-op, which influenced me a lot with setting my own goals. I also learned a lot about working and studying abroad, something I always dreamed of."',
      picture: Alice,
    },
    {
      participantName: "Prithika Hariharan",
      testimonial:
        '"My mentor helped connect me to other upper years who were involved in different roles in the tech industry and this opened me up to new opportunities."',
      picture: Prithika,
    },
    {
      participantName: "Linna Luo",
      testimonial:
        '"My mentor helped me build confidence in my technical skills and navigate a balanced lifestyle."',
      picture: Linna,
    },
    {
      participantName: "Mapendo Ngilinga de Carvalho",
      testimonial:
        '"It\'s like having your own personal guide for your tech journey!"',
      picture: Mapendo,
    },
  ];

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        Testimonials from our Participants
      </Typography>
      <Grid container spacing={10} className={classes.container}>
        {testimonials.map((participant) => (
          <Grid item xs={6}>
            <Testimonial
              participantName={participant.participantName}
              testimonial={participant.testimonial}
              picture={participant.picture}
            ></Testimonial>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
