import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  fullWidthRoot: {
    float: "left",
    margin: "3%",
  },
  header: {
    fontSize: "20px",
    fontWeight: "600",
    fontFamily: `"Poppins"`,
  },
  bar: {
    justifyContent: "center",
    textAlign: "center",
    position: "static",
    background: `
      linear-gradient(#000000,#000000) left -6px top     0  /100% 4px,
      linear-gradient(#000000,#000000) top  -6px left    0  /4px 100%,
      linear-gradient(#000000,#000000) left -6px bottom 6px/100% 4px,
      linear-gradient(#000000,#000000) top  -6px right  6px/4px 100%,
      linear-gradient(${fade(
        theme.palette.secondaryGreenLight.main,
        0.5
      )}, ${fade(theme.palette.secondaryGreenLight.main, 0.5)}) 10px 10px; 
    `,
    backgroundRepeat: "no-repeat",
    padding: "20px",
    width: "auto",
    minWidth: "200px",
  },
  searchFields: {
    width: "100%",
    display: "inline-block",
  },
  formControl: {
    margin: "0 4px 0 0",
    padding: "0 4px 8px 4px",
    minWidth: 120,
    maxWidth: 240,
    background: "#FFFFFF",
    border: "1px solid #000",
    width: "100%",
  },
  label: {
    padding: "0 8px 0 8px",
  },
  select: {
    "& .MuiSvgIcon-root": {
      color: "#000000 !important",
    },
    "demo-mutiple-chip-label": {
      color: "#000000 !important",
    },
    width: "auto",
    margin: "0 4px 0 4px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.5),
    padding: "1px 0 1px 0",
    height: "100%",
    "& .MuiChip-label": {
      wordWrap: "break-word",
      whiteSpace: "normal",
      textOverflow: "clip",
    },
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  collapsedFilterbar: {
    width: "100%",
    display: "inline-block",
    backgroundColor: theme.palette.secondaryGreenLight.main,
  },
  mobileRoot: {
    position: "relative",
    left: "0%",
    right: "0%",
    top: "0%",
    bottom: "50%",
    width: "100%",
  },
  topBar: {
    width: "100%",
    margin: "0 0 8px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #000000",
  },
  title: {
    fontWeight: "800",
    fontSize: "20px",
    width: "auto",
    color: "#000000",
    padding: theme.spacing(2),
    margin: "0 0 0 8px",
  },
  drawer: {
    width: "100%",
    height: "100%",
  },
  drawerWrapper: {
    width: "100%",
    height: "100%",
    minWidth: "260px",
    maxWidth: "400px",
    maxHeight: "100%",
    overflow: "scroll",
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  clearButton: {
    margin: theme.spacing(2),
    padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
    color: "#FFFFFF",
    background: theme.palette.secondaryGreenLight.main,
    borderRadius: "0",
    textTransform: "capitalize",
  },
  setFiltersButton: {
    width: "100%",
    padding: theme.spacing(1),
    color: "#FFFFFF",
    background: theme.palette.secondaryGreenLight.main,
    borderRadius: "0",
    textTransform: "capitalize",
    bottom: "0",
    position: "absolute",
    display: "inline",
  },
  appBar: {
    background: theme.palette.secondaryGreenLight.main,
    position: "sticky",
    top: "0",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "6%",
    marginRight: "6%",
    maxWidth: "100%",
  },
  menuButton: {
    marginRight: "5%",
  },
}));

export default useStyles;
