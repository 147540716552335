import Tailai from "../../../assets/images/people/tailai.jpg";
import TailaiTall from "../../../assets/images/people/tailai-2.jpg";
import Lagan_T from "../../../assets/images/people/Lagan_T.jpg";
import Lagan from "../../../assets/images/people/Lagan.jpg";
import Vivian_T from "../../../assets/images/people/Vivian_T.jpg";
import Vivian from "../../../assets/images/people/Vivian.jpg";
import Simran_T from "../../../assets/images/people/Simran_T.jpg";
import Simran from "../../../assets/images/people/Simran.jpg";
import Moe_T from "../../../assets/images/people/Moe_T.jpg";
import Moe from "../../../assets/images/people/Moe.jpg";

const stories = [
  {
    index: 0,
    name: "Tailai Wang",
    role: "Mentee class: Winter 2020",
    story:
      'Starting at UW felt like a daunting task. It\'s full of intimidating "firsts": the first set of midterms, the first round of WaterlooWorks, and that first group of interviews. Nothing brought more relief than having a great support system comprised of students who went through the same thing I did. I think I learned the most by doing interview prep with my mentors, who really helped me get my first co-op job. Tech+ brought guidance and insight that allowed to dive confidently into student life at UW!',
    tallImage: TailaiTall,
    status: "Mentee",
    thumbnailImage: Tailai,
  },
  {
    index: 1,
    name: "Lagan Bansal",
    role: "Mentee class: Winter 2020",
    story:
      "This program was really amazing. My mentor was really supportive and I got to learn a lot from her when she told me about her experiences. I really loved our bubble tea hangouts where we talked about all the different opportunities in tech especially in the fields I was interested in. I was able to get a lot of suggestions on how to improve my resume and how to become more involved in the tech industry by joining clubs or going to conferences and networking. I learned a lot about how to get started with side projects and come up with ideas if I get stuck. This program was really fun and enjoyable for me. I really learned a lot and met a lot of awesome people!",
    tallImage: Lagan_T,
    status: "Mentee",
    thumbnailImage: Lagan,
  },
  {
    index: 2,
    name: "Vivian Liu",
    role: "Mentee class: Winter 2020",
    story:
      "Starting university this year, I found it super helpful to reach out to my mentor about navigating student life. From technical interview prep to discovering good food spots around Waterloo, I definitely learnt a lot and met some amazing people along the way. Shout out to Katherine for being an incredible mentor",
    tallImage: Vivian_T,
    status: "Mentee",
    thumbnailImage: Vivian,
  },
  {
    index: 3,
    name: "Simran Thind",
    role: "Mentee class: Winter 2020",
    story:
      "The Tech+ Mentorship program was an invaluable experience for me! As someone who was nervous about navigating co-op applications, it really helped to have a mentor (shoutout to Karolina Xin!) who was able to give me great advice on building my resume, interview preparation, and getting through school in general. I highly encourage applying and tapping into this network of awesome upper-year students!",
    tallImage: Simran_T,
    status: "Mentee",
    thumbnailImage: Simran,
  },
  {
    index: 4,
    name: "Moe Amadou",
    role: "Mentee class: Fall 2019",
    story:
      "The value of the program for me was two-fold. Not only did I get to know my super thoughtful and wickedly talented mentor Kristy, but it helped demystify the software industry for me and learn about really valuable opportunities that I could get involved in immediately. I’d say that the biggest thing that I got out of the program though was seeing real, human examples of the things that were possible. You get to meet a lot of great people who are accomplished in their own regard, and though that won’t give you a blueprint for your definition of success, it opens your mind to tonnes of different possibilities that don’t feel abstract or far away.",
    tallImage: Moe_T,
    status: "Mentee",
    thumbnailImage: Moe,
  },
];

export default stories;
