import React from "react";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import classNames from "classnames";

import useStyles from "./UpcomingEventsCard-jss";
import { GAnalyticsPostEvent } from "../../../utils";

export default function UpcomingEventsCard({
  imageUrl,
  eventTitle,
  eventDate,
  signUpLink,
  buttonTitle,
  eventTrackingData,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.cardContainer}>
      <img src={imageUrl} alt="Event" />
      <CardContent>
        <Typography gutterBottom variant="h6">
          {eventTitle}
        </Typography>
        {eventDate && (
          <Typography gutterBottom variant="subtitle2">
            {eventDate}
          </Typography>
        )}

        <Button
          component={Link}
          href={signUpLink}
          target="_blank"
          variant="outlined"
          color="inherit"
          disabled={!signUpLink}
          className={classNames(classes.button, {
            [classes.disabledBtn]: !signUpLink,
          })}
          onClick={() => GAnalyticsPostEvent(...eventTrackingData)}
        >
          {buttonTitle}
        </Button>
      </CardContent>
    </Card>
  );
}
