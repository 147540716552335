import React, { useCallback, useEffect, useState } from "react";
import useStyles from "./MentorDisplay-jss";
import MentorDisplayCard from "../MentorDisplayCard/MentorDisplayCard";
import MentorFilterBar from "../MentorFilterBar/MentorFilterBar";
import Pagination from "../Pagination/Pagination";

import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import {
  expertiseList,
  programsList,
  termsList,
  mobileScreenSize,
  mobileProfilesPerPage,
  fullsizeProfilesPerPage,
  getProgramLabel,
  getTermLabel,
  getExpertiseLabel,
  getTimezoneLabel,
} from "src/constants";
import { jsonKeysToCamelCase } from "src/utils/formatting";

export function MentorDisplay() {
  const classes = useStyles();

  const { getAccessTokenSilently } = useAuth0();
  const [mentors, setMentors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [programFilters, setProgramFilters] = useState([]);
  const [termFilters, setTermFilters] = useState([]);
  const [expertiseFilters, setExpertiseFilters] = useState([]);

  const [loading, setLoading] = useState(true);
  const getIsMobile = () => window.innerWidth <= mobileScreenSize;
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const profilesPerPage = React.useRef(12);

  // check for mobile use
  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      profilesPerPage.current = mobileProfilesPerPage;
    } else {
      profilesPerPage.current = fullsizeProfilesPerPage;
    }
  }, [isMobile]);

  const convertToQueryParam = (params, paramList, paramName) => {
    var query = "";
    params &&
      params.forEach((param) => {
        const paramObject = paramList.find(
          (listItem) => listItem.label === param
        );
        query += `${paramName}=${encodeURIComponent(paramObject.value)}&`;
      });
    return query;
  };

  const getFormattedUrl = useCallback(
    (expertise, programs, terms, pageNumber) => {
      var url = process.env.REACT_APP_TECHPLUS_API;
      url += "/mentors/?";
      url += `profiles_per_page=${profilesPerPage.current}&`;
      if (
        (!expertise && !terms && !programs) ||
        (Object.keys(expertise).length === 0 &&
          Object.keys(terms).length === 0 &&
          Object.keys(programs).length === 0)
      ) {
        url += `page_number=${pageNumber}`;
      } else {
        url += `page_number=${pageNumber}&`;
        url += convertToQueryParam(expertise, expertiseList, "expertise");
        url += convertToQueryParam(terms, termsList, "terms");
        url += convertToQueryParam(programs, programsList, "programs");
      }
      if (url.slice(-1) === "&") {
        url = url.slice(0, -1);
      }
      return url;
    },
    []
  );

  const callApi = useCallback(
    async (expertise, programs, terms, pageFlip = false, pageNumber = 1) => {
      const token = await getAccessTokenSilently();
      const url = getFormattedUrl(expertise, programs, terms, pageNumber);
      try {
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        // if filters have been change reset pagination to first page
        if (!pageFlip) {
          setNumberOfPages(1);
        }
        setNumberOfPages(data.numPages);
        setMentors(
          data.mentors.map((mentor) => {
            return jsonKeysToCamelCase(mentor);
          })
        );
        setFilterSelections(data.filter_selections);
        setLoading(false);
      } catch (error) {}
    },
    [getFormattedUrl, getAccessTokenSilently]
  );

  const sendPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    callApi();
  }, [callApi]);

  const setFilterSelections = (filterSelections) => {
    const terms = new Set(filterSelections.terms);
    const programs = new Set(filterSelections.programs);
    const expertise = new Set(filterSelections.expertise);

    setTermFilters(terms);
    setProgramFilters(programs);
    setExpertiseFilters(expertise);
  };

  return (
    <>
      <div className={classes.root}>
        <Box display={{ xs: "none", md: "block" }} container spacing={3}>
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography className={classes.title}>Mentor Profiles</Typography>
          </Grid>
        </Box>
        <Grid item>
          <MentorFilterBar
            isMobile={isMobile}
            currentPage={currentPage}
            callApi={callApi}
            termFilters={termFilters}
            expertiseFilters={expertiseFilters}
            programFilters={programFilters}
            loading={loading}
          />
        </Grid>
        <Grid container spacing={3} className={classes.container}>
          <Grid
            container
            xs={16}
            sm={12}
            spacing={1}
            className={classes.mentors}
          >
            {mentors && mentors[0] ? (
              mentors.map((mentor) => {
                return (
                  <Grid item xs={24} sm={4}>
                    <MentorDisplayCard
                      key={mentor.email}
                      name={mentor.displayName}
                      program={getProgramLabel(mentor.academicProgram) || ""}
                      term={getTermLabel(mentor.termLevel) || ""}
                      desc={mentor.desc}
                      pronouns={mentor.pronouns}
                      areasOfExpertise={mentor.areasOfExpertise?.map(
                        (expertise) => getExpertiseLabel(expertise)
                      )}
                      email={mentor.email}
                      askMeAbout={mentor.askMeAbout}
                      hobbies={mentor.hobbies}
                      linkedin={mentor.linkedin}
                      imageKey={mentor.imageKey}
                      timeZone={getTimezoneLabel(mentor.timeZone)}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12} className={classes.titleContainer}>
                <Typography>No Matches</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Pagination
              numberOfPages={numberOfPages}
              sendPage={sendPage}
              key={numberOfPages}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withAuthenticationRequired(MentorDisplay);
