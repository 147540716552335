import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3% 8% 7% 8%",
  },
  header: {
    fontWeight: "600",
    fontSize: "48px",
    margin: 0,
    paddingLeft: "24px",
  },
  appBar: {
    padding: "0%",
    width: "85%",
    margin: "auto",
  },
  tab: {
    margin: "2% 1%",
    padding: 0,
  },
  tabSelected: {
    background: theme.palette.primary.main,
    margin: "2% 1%",
    padding: 0,
  },
  tabName: {
    alignItems: "left",
  },
  tile: {
    padding: "2em 7em",
  },
  text: {
    fontSize: "18px",
  },
  panel: {
    height: "auto",
    "& h5": {
      color: "green",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "27px",
      margin: "0%",
    },
    "& h1": {
      margin: "1% 0",
      fontSize: "36px",
      lineHeight: "54px",
      fontWeight: "500",
    },
  },
  portraitContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  portrait: {
    width: "70%",
    height: "auto",
    margin: "5%",
  },
  thumbnailPic: {
    width: "230px",
    height: "auto",
    padding: "6% 10%",
  },
  smallName: {
    fontSize: "14px",
    fontWeight: "600",
    margin: 0,
    padding: 0,
  },
  smallNameSelected: {
    fontSize: "14px",
    fontWeight: "600",
    margin: 0,
    padding: 0,
    color: "#fff",
  },
  labelSubtext: {
    color: "green",
    fontSize: "12px",
    fontWeight: "400",
  },
  labelSubtextSelected: {
    color: "#FFDC84",
    fontSize: "12px",
    fontWeight: "400",
    margin: "10% 0 0 0",
  },
  largeContainer: {
    "& p": {
      fontSize: "1.5vw",
      fontWeight: "400",
      lineHeight: "36px",
      marginTop: "7%",
    },
  },
  resizedContainer: {
    "& p": {
      fontSize: "2.4vw",
    },
  },
  resizedPortrait: {
    "& img": {
      width: "60%",
    },
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
