import React, { useState } from "react";
import { useStore } from "../../../utils/store";
import ImageUploading from "react-images-uploading";
import useStyles from "./ProfilePictureUploader-jss";
import { Button, Avatar } from "@material-ui/core";

export default function ProfilePictureUploader() {
  const [imageList, setImages] = useState(null);
  const setProfileImageDataUrl = useStore((s) => s.setProfileImageDataUrl);
  const setProfileImageChanged = useStore((s) => s.setProfileImageChanged);
  const classes = useStyles();

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    setProfileImageDataUrl(imageList[0]?.data_url || "");
    setProfileImageChanged(true);
  };

  const MAX_FILE_SIZE = 15000 * 1000; // ideally photos are 150kb * 1000b.

  return (
    <ImageUploading
      value={imageList}
      onChange={onChange}
      dataURLKey="data_url"
      maxFileSize={MAX_FILE_SIZE}
    >
      {({ imageList, onImageUpload, onImageRemoveAll, dragProps }) => (
        <div>
          {imageList.length ? (
            <>
              <Avatar className={classes.avatar} src={imageList[0].data_url} />
              <Button onClick={onImageRemoveAll}>Remove</Button>
            </>
          ) : (
            <Button
              className={classes.dropzone}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or drop here to upload.
            </Button>
          )}
        </div>
      )}
    </ImageUploading>
  );
}
