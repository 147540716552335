import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(168.48deg, #FFFFFF 32.06%, rgba(255, 238, 194, 0.4) 77.51%)",
    padding: "20px 0",
  },
  headerContainer: {
    padding: "0 10%",
  },
  header: {
    fontWeight: "600",
    fontSize: "36px",
  },
  programsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "100px",
    marginBottom: "100px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
}));

export default useStyles;
