import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5% 10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(187.51deg, #FFF6DE 20px, #FFFFFF 600px)",
    marginTop: "140px",
    "@media (min-width: 900px) and (max-width: 1000px)": {
      padding: "5%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5%",
    },
  },
  title: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "72px",
    color: theme.palette.primary.main,
    textAlign: "center",
    marginTop: "0",
  },
  desc: {
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "27px",
    textAlign: "center",
    color: theme.palette.primary.main,
    maxWidth: "720px",
    marginTop: "0",
  },
  grid: {
    display: "flex",
    flexWrap: "wrap",
    padding: "30px 10%",
    justifyContent: "center",
    "@media (max-width: 1450px)": {
      padding: "30px 0",
    },
  },
}));

export default useStyles;
