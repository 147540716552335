import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    "&:disabled": {
      backgroundColor: "rgba(139, 198, 119, 0.3)",
      color: "#FFFAF5",
    },
    color: "#FFFAF5",
    backgroundColor: "#8BC677",
    margin: 4.5,
    padding: 5,
    width: "38px",
    height: "39px",
  },
  select: {
    margin: 4.5,
    background: "#FFFFFF",
    border: "1px solid #000000",
    boxSizing: "border-box",
    borderRadius: "0px",
    width: "68px",
    height: "38px",
    fontSize: "20px",
    lineHeight: "30px",
  },
  text: {
    fontFamily: `"Poppins"`,
    margin: 4.5,
    fontSize: "20px",
    lineHeight: "30px",
  },
  bar: {
    justifyContent: "right",
    alignItems: "center",
    position: "static",
    margin: "-4px",
  },
}));

export default useStyles;
