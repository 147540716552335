import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5% 10% 0% 10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "72px",
    color: theme.palette.primary.main,
    position: "relative",
  },
  eventCardsContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    gap: "90px",
    flexWrap: "wrap",
  },
}));

export default useStyles;
