import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  landing: {
    backgroundColor: theme.palette.primary.main,
    padding: "30px 10px",
  },
  landingContent: {
    maxWidth: "1500px",
    margin: "auto",
  },
  landingIntroContainer: {
    display: "flex",
    justifyContent: "center",
  },
  landingIntro: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "64px",
    padding: "30px 0px",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      fontSize: "58px",
    },
  },
  desc: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#fff",
    maxWidth: "500px",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
  img: {
    width: "100%",
    maxWidth: "500px",
    maxHeight: "400px",
  },
  buttons: {
    minWidth: "300px",
    marginTop: "39px",
    whiteSpace: "nowrap",
  },
  button1: {
    fontSize: "18px",
    color: "#FFFFFF",
    maxWidth: "221px",
    height: "55px",
    marginRight: "32px",
    margin: "5px 0px",
    textTransform: "none",
    borderRadius: "0",
    "&:hover": {
      background: theme.palette.secondaryGreenDark.main,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "5px",
    },
  },
  button2: {
    fontSize: "18px",
    color: "#FFFFFF",
    maxWidth: "165px",
    height: "55px",
    margin: "5px 0px",
    textTransform: "none",
    borderRadius: "0",
    "&:hover": {
      background: theme.palette.secondaryGreenDark.main,
    },
  },
  media: {
    display: "flex",
    margin: "20px 0px 0px 0px",
  },
  avatar1: {
    width: "46px",
    height: "46px",
    backgroundColor: theme.palette.secondaryGreenDark.main,
    marginRight: "35px",
  },
  avatar2: {
    width: "46px",
    height: "46px",
    backgroundColor: theme.palette.secondaryGreenDark.main,
    marginRight: "35px",
  },
  avatar3: {
    width: "46px",
    height: "46px",
    backgroundColor: theme.palette.secondaryGreenDark.main,
    marginRight: "35px",
  },
  avatar4: {
    width: "46px",
    height: "46px",
    color: "#fff",
    backgroundColor: theme.palette.secondaryGreenDark.main,
  },
  visionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  content: {
    width: "auto",
    fontStyle: "normal",
    color: theme.palette.primary.main,
    padding: "5% 15%",
    background:
      "linear-gradient(168.48deg, #FFFFFF 32.06%, rgba(255, 238, 194, 0.4) 77.51%)",
  },
  about: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "72px",
  },
  about_t: {
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "27px",
    maxWidth: "800px",
  },
  vision: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "54px",
    marginBottom: "0",
  },
  vision_t: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "27px",
    marginTop: "0",
  },
  mission: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  visionMissionPic: {
    display: "flex",
    justifyContent: "center",
  },
  sponsors: {
    marginTop: "160px",
    textAlign: "center",
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "72px",
  },
  sponsors_t: {
    textAlign: "center",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "27px",
  },
  sponsorPic: {
    display: "flex",
    justifyContent: "center",
    marginTop: "34px",
  },
  sponsorLogo: {
    margin: "0 34px",
    height: "140px",
  },
}));

export default useStyles;
