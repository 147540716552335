import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5% 10%",
  },
  title: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "72px",
    background:
      "linear-gradient(120deg, rgba(98, 170, 223, 0.51) 0%, rgba(98, 170, 223, 0.51) 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 40%",
    backgroundPosition: "0 90%",
    maxWidth: "180px",
    paddingLeft: "1%",
  },
  photoSection: {
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  mainArea: {
    padding: "30px 0px",
  },
  editArea: {
    padding: "0% 5%",
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    marginBottom: "20px",
  },
  button: {
    textTransform: "none",
    margin: "20px",
    borderRadius: 0,
  },
  name: {
    margin: "auto",
    padding: "7px",
    fontWeight: "600",
  },
  textField: {
    maxWidth: "700px",
    height: "6",
  },
  removeImageLink: {
    color: "#1A74A6",
  },
  topRightLeafStyle: {
    position: "absolute",
    top: "5%",
    right: 0,
    opacity: 0.4,
  },
  bottomLeftLeafStyle: {
    position: "absolute",
    bottom: "15%",
    left: 0,
    opacity: 0.4,
  },
  bottomRightLeafStyle: {
    position: "absolute",
    bottom: "10%",
    right: 0,
    opacity: 0.4,
  },
  topLeftLeafStyle: {
    position: "absolute",
    top: "-2%",
    left: "-1%",
    opacity: 0.4,
    zIndex: -1,
  },
}));
export default useStyles;
