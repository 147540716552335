import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useStyles from "./Program-jss";

import { EVENT_CATEGORIES, EVENT_ACTIONS } from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";

export default function Program({
  title,
  description,
  imageUrl,
  buttonText,
  buttonUrl,
  isRightAligned,
  btnClickEventTrackingLabel,
}) {
  const classes = useStyles();
  const programContainerClass = isRightAligned
    ? classes.programContainerRight
    : classes.programContainerLeft;
  const programCardClass = isRightAligned
    ? classes.programCardRight
    : classes.programCardLeft;

  return (
    <div className={programContainerClass}>
      <Card className={programCardClass}>
        <Typography className={classes.programTitle}>{title}</Typography>
        <Typography className={classes.programDesc}>{description}</Typography>
        {buttonText && buttonUrl && btnClickEventTrackingLabel && (
          <Button
            className={classes.programButton}
            variant="contained"
            color="primary"
            href={buttonUrl}
            target="_blank"
            disableElevation
            onClick={() =>
              GAnalyticsPostEvent(
                EVENT_CATEGORIES.LANDING_PAGE_INITIATIVES,
                EVENT_ACTIONS.CLICK,
                btnClickEventTrackingLabel
              )
            }
          >
            {buttonText}
          </Button>
        )}
      </Card>
      <img src={imageUrl} alt={title} className={classes.programImg} />
    </div>
  );
}
