import React from "react";
import Grid from "@material-ui/core/Grid";
import useStyles from "./Programs-jss";
import Program from "../Program";
import { programs } from "./config";

const Initiatives = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <p className={classes.header}>Our Programs</p>
      </div>
      <Grid container className={classes.programsContainer}>
        {programs.map((program) => (
          <Program
            title={program.title}
            description={program.description}
            imageUrl={program.imageUrl}
            isRightAligned={program.isRightAligned}
            buttonText={program.buttonText}
            buttonUrl={program.buttonUrl}
            btnClickEventTrackingLabel={program.btnClickEventTrackingLabel}
          />
        ))}
      </Grid>
    </div>
  );
};

export default Initiatives;
