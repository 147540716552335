import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
  },
  button: {
    fontSize: "18px",
    color: "#FFFFFF",
    lineHeight: "27px",
    height: "40px",
    marginTop: "5px",
    textTransform: "none",
    borderRadius: "0",
    "&:hover": {
      background: theme.palette.secondaryGreenDark.main,
    },
  },
  disabledBtn: {
    color: "#989696 !important",
    backgroundColor: "#2f2f2f9c",
  },
}));

export default useStyles;
