import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "746px",
    background: "#FFF0CB",
    marginTop: "130px",
    padding: "2% 10%",
  },
  header: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "72px",
    paddingTop: "72px",
  },
  container: {
    marginTop: "26px",
    marginBottom: "48px",
    "& .MuiGrid-grid-xs-6": {
      maxWidth: "100%",
    },
    "@media (max-width: 900px)": {
      display: "block",
    },
  },
}));

export default useStyles;
