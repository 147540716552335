import mentorshipImg from "../../../assets/images/programs/Mentorship.svg";
import generalEventsImg from "../../../assets/images/programs/GeneralEvents.svg";
import coffeeChatsImg from "../../../assets/images/programs/CoffeeChats.svg";
import firstYearResourcesImg from "../../../assets/images/programs/FirstYearResources.svg";
import coffeeChatGuide from "../../../assets/images/Coffee.pdf";

import { EVENT_LABELS } from "../../../constants";

export const programs = [
  {
    title: "Mentorship Project",
    description: `The Mentorship Program pairs students wanting to find their place 
    within the tech industry, either as a beginner or someone looking to transition, 
    with an upper-year mentor. Mentors will provide guidance, offer insight and act 
    as a resource in their mentee's introduction to the tech industry. We hope to 
    create a culture that encourages individuality and personal growth. 
    The Mentorship Program facilitates the matching of pairs and introduces 
    participants to the larger Tech+ community.`,
    imageUrl: mentorshipImg,
    isRightAligned: true,
  },
  {
    title: "First Year Resources",
    description: `First Year Resources creates events, panels, and activities 
    to help ease the transition of first-years into the start of their journey 
    at the University of Waterloo. Some of our past initiatives include our 
    First Year Q&A Panel, Co-op 101, and our Dear Tech+ Advice column. 
    Look out for future workshops and panels to come!`,
    imageUrl: firstYearResourcesImg,
    isRightAligned: false,
  },
  {
    title: "Events",
    description: `General Events consists of panels, workshops, and public 
    events to provide the skills and experiences UW students need in order 
    to succeed. General Events intends to address common barriers within the 
    UW tech community surrounding diversity & inclusion as well as preparing 
    students for a technical workplace setting. Past events include Resume 
    Critiques, Mock Interviews, an AI/ML Technical Workshop, and a fireside 
    chat on how to deal with impostor syndrome.`,
    imageUrl: generalEventsImg,
    isRightAligned: true,
  },
  {
    title: "Coffee Chats Project",
    description: `Coffee chats are an opportunity for students to connect with 
    multiple upper-year mentors knowledgeable in the topics and domains they're 
    interested in. Students will have their questions answered, learn about 
    different career paths within tech, and add a new connection to their network. 
    New to W21, Coffee Chats is now opening the floor up for matches across 
    all years and programs!`,
    imageUrl: coffeeChatsImg,
    buttonText: "Coffee Chat Guide",
    buttonUrl: coffeeChatGuide,
    btnClickEventTrackingLabel: EVENT_LABELS.COFFEE_CHAT_GUIDE_BTN,
    isRightAligned: false,
  },
];

export const PAST_EVENT_TAB_TRACKING_LABELS = [
  EVENT_LABELS.PAST_EVENTS_MENTORSHIP_PRGM,
  EVENT_LABELS.PAST_EVENTS_EXP_SHARING,
  EVENT_LABELS.PAST_EVENTS_FIRST_YEAR_RESOURCES,
  EVENT_LABELS.PAST_EVENTS_RESUME_INTERVIEW_PREP,
];

export const tabNames = [
  "Mentorship Program",
  "Experience Sharing",
  "First Year Resources",
  "Resume + Interview Prep",
];

export const eventsSections = [
  [
    // Mentorship event section
    {
      eventName: "Summer 2022 Mentorship Program",
      description: "43 Mentor/Mentee Pairs",
    },
    {
      eventName: "Winter 2022 Mentorship Program",
      description: "57 Mentor/Mentee Pairs",
    },
    {
      eventName: "Fall 2021 Mentorship Program",
      description: "77 Mentor/Mentee Pairs",
    },
    {
      eventName: "Summer 2021 Mentorship Program",
      description: "181 Mentor/Mentee Pairs",
    },
    {
      eventName: "Winter 2021 Mentorship Program",
      description: "159 Mentor/Mentee Pairs",
    },
    {
      eventName: "Fall 2020 Mentorship Program",
      description: "125 Mentor/Mentee Pairs",
    },
    {
      eventName: "Summer 2020 Mentorship Program",
      description: "108 Mentor/Mentee Pairs",
    },
    {
      eventName: "Winter 2020 Mentorship Program",
      description: "63 Mentor/Mentee Pairs",
    },
  ],
  [
    // Experience sharing event section
    {
      eventName: "Breaking into Tech with David Li - June '22",
      description: "",
      btnClickEventTrackingLabel:
        EVENT_LABELS.PAST_EVENTS_BREAKING_INTO_TECH_DAVID_LI,
      links: [
        {
          link: "https://us02web.zoom.us/rec/share/QdDVUmnwbE2AB9nl0LIizGGSE6QPDI8iZaZ_Dj6HLRp74W1ES5gVuOnjjXbthJcL.LpqBVRDLBCM8SIrA?startTime=1656626801000",
          text: "See Recording",
        },
      ],
    },
    {
      eventName: "In-person Networking Event - July '22",
      description: "",
    },
    {
      eventName: "Byte-Sized Conference - April '22",
      description: "",
      links: [
        {
          link: "https://drive.google.com/file/d/1vkAnTMLdomIG2JYr6koSka-4TPLbBqM2/view",
          text: "Intro to UI/UX Workshop",
        },
        {
          link: "https://us02web.zoom.us/rec/share/gZV6-0lF7o3zzEyNml8N0D-Ds8q-3qWmbToZzzVVdFS-ZZFWSVfs5omcyuztGR0r.6zlVCVuEFin4qKj0?startTime=1648938692000",
          text: "Intro to Frontend Development with React",
        },
        {
          link: "https://us02web.zoom.us/rec/share/gZV6-0lF7o3zzEyNml8N0D-Ds8q-3qWmbToZzzVVdFS-ZZFWSVfs5omcyuztGR0r.6zlVCVuEFin4qKj0?startTime=1648942303000",
          text: "Intro to Databases with MongoDB",
        },
        {
          link: "https://us02web.zoom.us/rec/share/gZV6-0lF7o3zzEyNml8N0D-Ds8q-3qWmbToZzzVVdFS-ZZFWSVfs5omcyuztGR0r.6zlVCVuEFin4qKj0?startTime=1648946042000",
          text: "Breaking the Glass Ceiling in Tech Panel",
        },
      ],
    },
    {
      eventName: "UW x Tesla Coffee Chats - March '22",
      description: "",
    },
    {
      eventName: "Mentorship Program Lightning Talks - March '22",
      description: "",
      links: [
        {
          link: "https://us02web.zoom.us/rec/share/wppbZ71dF6WNyEm6CK8HpjE7GTHK2FTk11MARDcKtatXPsjOho9_2JswqOcF8-mP.U1JGVki0A_iOA7T9?startTime=1647122755000",
          text: "See Recording",
        },
      ],
    },
    {
      eventName: "All Hands on Tech Conference - November '21",
      description: "61 attendees",
    },
    {
      eventName: "Sustainable Leaders in Tech - July '21",
      btnClickEventTrackingLabel:
        EVENT_LABELS.PAST_EVENTS_SUSTAINABLE_LEADERS_IN_TECH_BTN,
      links: [
        {
          link: "https://us02web.zoom.us/rec/share/J5XjggAhipSSDxmhTeco1iEnd3rWIAVJK7QC8tJvk6PaK0ONxrCJiCIUglHkF-Fw.kwJBHeX34noIwJO9?startTime=1626910585000",
          text: "See Recording",
        },
      ],
    },
    {
      eventName: "My Pride in Tech - June '21",
      description: "60 attendees",
    },
    {
      eventName: "Navigating the Tech Landscape with Myoung Kang - March '21",
      description: "60 attendees",
    },
    {
      eventName: "CSC Impostor Syndrome Event Fireside Chat - February '21",
      description: "35 attendees",
    },
    {
      eventName: "Work Abroad Experience Sharing - July '20",
      description: "",
      btnClickEventTrackingLabel:
        EVENT_LABELS.PAST_EVENTS_WORK_ABROAD_RECAP_BTN,
      links: [
        {
          link: "https://www.youtube.com/watch?v=IMCXAZvUx3k&t=1s",
          text: "See Recording",
        },
      ],
    },
    {
      eventName: "Study Abroad Experience Sharing - June '20",
      description: "",
      btnClickEventTrackingLabel:
        EVENT_LABELS.PAST_EVENTS_STUDY_ABROAD_RECAP_BTN,
      links: [
        {
          link: "https://medium.com/techplusuw/study-abroad-experience-sharing-624e3db077d8",
          text: "See Recording",
        },
      ],
    },
  ],
  [
    // First year resources event section
    {
      eventName: "Tech+ x Hypotenuse Labs: Blockchain Workshop - November '21",
      description: "34 attendees",
    },
    {
      eventName: "Tech+ x D2L: Landing Your Dream Co-op - October '21",
      description: "92 attendees",
    },
    {
      eventName: "Financial Literacy Workshop - March '21",
      description: "",
      btnClickEventTrackingLabel:
        EVENT_LABELS.PAST_EVENTS_FINANCIAL_LITERACY_WORKSHOP_BTN,
      links: [{ link: "https://youtu.be/66VkmPNcEs4", text: "See Recording" }],
    },
    {
      eventName: "Tech+ Coop 101 Workshop and Panel",
      description: "",
      btnClickEventTrackingLabel: EVENT_LABELS.PAST_EVENTS_101_WORKSHOP_BTN,
      links: [
        {
          link: "https://www.youtube.com/watch?v=jPiPH5KBjNA",
          text: "See Recording",
        },
      ],
    },
    {
      eventName: "AI/ML Technical Workshop",
      description: "",
      btnClickEventTrackingLabel: EVENT_LABELS.PAST_EVENTS_AI_ML_WORKSHOP_BTN,
      links: [
        {
          link: "https://www.youtube.com/watch?v=I-DlBSFNTRo",
          text: "See Recording",
        },
      ],
    },
  ],
  [
    // Resume + interview prep event section
    {
      eventName: "Interview 101 Workshop - Jan ‘22",
      description: "",
    },
    {
      eventName: "Mock Interview - Sept ‘21",
      description: "40 Interviews Conducted",
    },
    {
      eventName: "Resume Critique - Sept ‘21",
      description: "130 Resumes Reviewed",
    },
    {
      eventName: "Resume Critique - May ‘21",
      description: "130 Resumes Reviewed",
    },
    {
      eventName: "Mock Interview - Feb ‘21",
      description: "124 Interviews Conducted",
    },
    {
      eventName: "Resume Critique - Jan ‘21",
      description: "252 Resumes Reviewed",
    },
    {
      eventName: "Resume Critique - Sept ‘20",
      description: "246 Resumes Reviewed",
    },
    {
      eventName: "Mock Interview - June ‘20",
      description: "120 Interviews Conducted",
    },
    {
      eventName: "Resume Critique - May ‘20",
      description: "150 Resumes Reviewed",
    },
  ],
];
