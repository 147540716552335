import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderBottom: "1px solid",
  },
  row: {
    display: "inline-block",
  },
  eventName: {
    float: "left",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "36px",
  },
  button: {
    fontSize: "14px",
    width: "auto",
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    lineHeight: "21px",
    height: "38px",
    textTransform: "none",
    marginBottom: "7px",
    marginLeft: "4px",
    marginRight: "4px",
    borderRadius: "4px",
    "&:hover": {
      background: theme.palette.secondaryGreenDark.main,
    },
  },
  info: {
    float: "right",
    fontSize: "14px",
    lineHeight: "21px",
    paddingTop: "7px",
    paddingRight: "7px",
  },
}));

export default useStyles;
