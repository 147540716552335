import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import useStyles from "./TeamMemberDisplay-jss";
import theme from "src/components/theme";

import {
  EVENT_ACTIONS,
  EVENT_LABELS,
  EVENT_CATEGORIES,
} from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";

const trackingNameParser = (memberName) =>
  memberName.replaceAll(" ", "_").replaceAll(".", "").toLowerCase();

export default function TeamMemberDisplay({
  name,
  role,
  photoUrl,
  linkedInUrl,
}) {
  const classes = useStyles();

  return (
    <Grid item className={classes.member} md={3} sm={5} xs={12}>
      <div>
        <Avatar
          src={photoUrl}
          className={classes.avatar}
          alt="Team Member Avatar"
        />
        <Typography color={theme.palette.primary.main} className={classes.name}>
          {name}
        </Typography>
        <Typography
          color={theme.palette.secondary.main}
          className={classes.role}
          aria-label="Team Member LinkedIn"
        >
          {role}
        </Typography>
        {linkedInUrl && (
          <Link
            href={linkedInUrl}
            target="_blank"
            onClick={() =>
              GAnalyticsPostEvent(
                EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                EVENT_ACTIONS.CLICK,
                `${
                  EVENT_LABELS.MEET_OUR_TEAM_LINKEDIN_ICON
                }_${trackingNameParser(name)}`
              )
            }
          >
            <LinkedInIcon />
          </Link>
        )}
      </div>
    </Grid>
  );
}
