import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleSection: {
    padding: "0 11.5%",
  },
  title: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "72px",
    color: theme.palette.primary.main,
    marginTop: 0,
  },
  displayContainer: {
    padding: "0 5%",
  },
  carouselContainer: {
    padding: "0 10%",
    "@media (min-width: 1040px)": {
      padding: "0 5%",
    },
  },
  desc: {
    fontSize: "24px",
    lineHeight: "36px",
    color: theme.palette.primary.main,
    margin: 0,
  },
  cardContainer: {
    "@media (min-width: 1040px)": {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "repeat(1, 100%)",
      height: "55vh",
    },
    "@media (min-width: 1265px)": {
      height: "45vh",
    },
    "@media (min-width: 1500px)": {
      height: "55vh",
    },
  },
  card: {
    gridColumnEnd: "span 1",
    gridRowEnd: "span 1",
    margin: "5%",
  },
}));

export default useStyles;
