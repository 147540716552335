import ReactGA from "react-ga";

/**
 * This function sends an event for Google Analytics to track. 
 * Use the `EVENT_CATEGORIES`, `EVENT_ACTIONS`, and `EVENT_LABELS` 
 * defined in GoogleAnalytics.js
 */

export const GAnalyticsPostEvent = (category, action, label) =>
  ReactGA.event({
    category,
    action,
    label,
  });
