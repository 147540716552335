import React from "react";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import Link from "@material-ui/core/Link";
import { grey } from "@material-ui/core/colors";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import useStyles from "./Footer-jss";

import { EVENT_ACTIONS, EVENT_LABELS } from "../../constants";
import { GAnalyticsPostEvent } from "../../utils";

export default function Footer(props) {
  const classes = useStyles();

  return (
    <div>
      <BottomNavigation className={classes.nav}>
        <div className={classes.icons}>
          <Link
            href="https://www.linkedin.com/company/techplusuw/"
            aria-label="Tech Plus LinkedIn"
            target="_blank"
            onClick={() =>
              GAnalyticsPostEvent(
                props.trackingEventCategory,
                EVENT_ACTIONS.CLICK,
                EVENT_LABELS.FOOTER_LINKEDIN_ICON
              )
            }
          >
            <LinkedInIcon
              fontSize="large"
              style={{
                color: grey[50],
                paddingRight: "5px",
                paddingLeft: "5px",
              }}
            />
          </Link>
          <Link
            href="https://twitter.com/techplusuw"
            aria-label="Tech Plus Twitter"
            target="_blank"
            onClick={() =>
              GAnalyticsPostEvent(
                props.trackingEventCategory,
                EVENT_ACTIONS.CLICK,
                EVENT_LABELS.FOOTER_TWITTER_ICON
              )
            }
          >
            <TwitterIcon
              fontSize="large"
              style={{
                color: grey[50],
                paddingRight: "5px",
                paddingLeft: "5px",
              }}
            />
          </Link>
          <Link
            href={"mailto:techplusuw@gmail.com"}
            aria-label="Tech Plus Email"
            target="_blank"
            onClick={() =>
              GAnalyticsPostEvent(
                props.trackingEventCategory,
                EVENT_ACTIONS.CLICK,
                EVENT_LABELS.FOOTER_MAIL_ICON
              )
            }
          >
            <EmailIcon
              fontSize="large"
              style={{
                color: grey[50],
                paddingRight: "5px",
                paddingLeft: "5px",
              }}
            />
          </Link>
          <Link
            href="https://www.facebook.com/techplusuw/"
            aria-label="Tech Plus Facebook"
            target="_blank"
            onClick={() =>
              GAnalyticsPostEvent(
                props.trackingEventCategory,
                EVENT_ACTIONS.CLICK,
                EVENT_LABELS.FOOTER_FACEBOOK_ICON
              )
            }
          >
            <FacebookIcon
              fontSize="large"
              style={{
                color: grey[50],
                paddingRight: "5px",
                paddingLeft: "5px",
              }}
            />
          </Link>
          <Link
            href="https://www.instagram.com/techplusuw/"
            aria-label="Tech Plus Instagram"
            target="_blank"
            onClick={() =>
              GAnalyticsPostEvent(
                props.trackingEventCategory,
                EVENT_ACTIONS.CLICK,
                EVENT_LABELS.FOOTER_INSTAGRAM_ICON
              )
            }
          >
            <InstagramIcon
              fontSize="large"
              style={{
                color: grey[50],
                paddingRight: "5px",
                paddingLeft: "5px",
              }}
            />
          </Link>
        </div>
        <div className={classes.bottom}>
          <p>@ 2022 Tech+ UW</p>
        </div>
      </BottomNavigation>
    </div>
  );
}
