import React, { useState } from "react";
import _ from "lodash";

import useStyles from "./KeepInTouch-jss";
import { Alert } from "@material-ui/lab";
import {
  Typography,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { GoogleSpreadsheet } from "google-spreadsheet";

import {
  EVENT_CATEGORIES,
  EVENT_ACTIONS,
  EVENT_LABELS,
} from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";

const debouncedFullNameInputEventTracking = _.debounce(() => {
  GAnalyticsPostEvent(
    EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
    EVENT_ACTIONS.TYPE,
    EVENT_LABELS.KEEP_IN_TOUCH_FULL_NAME_INPUT
  );
}, 2000);

const debouncedEmailInputEventTracking = _.debounce(() => {
  GAnalyticsPostEvent(
    EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
    EVENT_ACTIONS.TYPE,
    EVENT_LABELS.KEEP_IN_TOUCH_EMAIL_INPUT
  );
}, 2000);

export default function KeepInTouch() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const inputFields = [
    { placeholder: "Full Name*", type: "text", name: "name" },
    { placeholder: "Email*", type: "email", name: "email" },
  ];

  const ALERT_TYPES = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
  };

  const ALERT_DESC_MAP = {
    [ALERT_TYPES.SUCCESS]: "Subscription successful!",
    [ALERT_TYPES.ERROR]: "Email has already been subscribed.",
    [ALERT_TYPES.WARNING]: "Subscription failed, please try again.",
  };

  const addToSpreadSheet = async (name, email) => {
    setIsLoading(true);
    setAlertType("");

    try {
      const doc = new GoogleSpreadsheet(
        process.env.REACT_APP_GOOGLE_SPREADSHEET_ID
      );

      await doc.useServiceAccountAuth({
        client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
          /\\n/g,
          "\n"
        ),
      });
      await doc.loadInfo();

      // adds to the first sheet the name and email to the name and email column, respectively
      const sheet = doc.sheetsByTitle["Emails from Website"];
      await sheet.loadCells();

      const columnIndex = 0;
      let isExistingEmail = false;

      for (let rowIndex = 1; rowIndex < sheet.rowCount; rowIndex++) {
        const {
          _rawData: { formattedValue },
        } = sheet.getCell(rowIndex, columnIndex);

        if (formattedValue === email) {
          isExistingEmail = true;
          break;
        }
      }

      if (!isExistingEmail) {
        await sheet.addRow({ Email: email, Name: name });
        setAlertType(ALERT_TYPES.SUCCESS);
      } else {
        setAlertType(ALERT_TYPES.ERROR);
      }
    } catch {
      setAlertType(ALERT_TYPES.WARNING);
    }

    setIsLoading(false);
  };

  const handlePost = (e) => {
    e.preventDefault();
    addToSpreadSheet(name, email);
  };

  const handleInputChange = (e) => {
    const newVal = e.target.value;
    if (e.target.name === "name") {
      debouncedFullNameInputEventTracking();
      setName(newVal);
    } else if (e.target.name === "email") {
      debouncedEmailInputEventTracking();
      setEmail(newVal);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertType("");
  };

  return (
    <>
      <Snackbar
        className={classes.subscriptionAlert}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={alertType}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertType}>
          {ALERT_DESC_MAP[alertType]}
        </Alert>
      </Snackbar>
      <div id="mailing" className={classes.root}>
        <Typography className={classes.title}>Keep In Touch!</Typography>
        <Typography className={classes.desc}>
          Join the Tech+ family and sign up for our mailing list to be the first
          to know about our newest events and initiatives!
        </Typography>
        <form
          onSubmit={(e) => handlePost(e)}
          id="keepInTouchForm"
          className={classes.form}
        >
          {inputFields.map((field) => (
            <TextField
              key={field.placeholder}
              required
              className={classes.inputField}
              variant="outlined"
              placeholder={field.placeholder}
              type={field.type}
              name={field.name}
              onChange={(e) => handleInputChange(e)}
            />
          ))}
          <Button
            disableElevation
            type="submit"
            form="keepInTouchForm"
            variant="contained"
            color="inherit"
            disabled={isLoading}
            className={classes.button}
            onClick={() =>
              GAnalyticsPostEvent(
                EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                EVENT_ACTIONS.CLICK,
                EVENT_LABELS.KEEP_IN_TOUCH_SUBSCRIBE_BTN
              )
            }
          >
            {isLoading ? (
              <CircularProgress size={30} className={classes.loader} />
            ) : (
              "Subscribe"
            )}
          </Button>
        </form>
      </div>
    </>
  );
}
