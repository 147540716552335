import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "10px",
    boxShadow: "3px 5px 4px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "0",
  },
  participantName: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "33px",
    marginLeft: "62px",
  },
  testimonials: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "25px",
    marginLeft: "62px",
  },
  picture: {
    position: "absolute",
    height: "85px",
    width: "85px",
    marginTop: "10px",
    marginLeft: "-43px",
  },
}));

export default useStyles;
