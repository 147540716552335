import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5% 0%",
  },
  diversityInclusion: {
    padding: "0% 12%",
  },
  resourcesContainer: {
    display: "flex",
    "& .MuiGrid-grid-xs-12": {
      maxWidth: "100%",
    },
    "@media (max-width: 1100px)": {
      display: "block",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  getInvolvedContainer: {
    "& .MuiGrid-container": {
      flexWrap: "nowrap",
    },
    "& .MuiGrid-root": {
      maxWidth: "100%",
    },
    "@media (max-width: 1300px)": {
      display: "block",
    },
  },
  linkContainer: {
    "@media (max-width: 600px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  centeredDivider: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  rightGetInvolvedLinks: {
    [theme.breakpoints.down("xs")]: {
      borderTop: "solid 1px #0000001f",
    },
  },
  subtitle: {
    marginTop: "100px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "54px",
    color: theme.palette.primary.main,
  },
  content: {
    width: "100%",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "27px",
    color: theme.palette.primary.main,
  },
  statementPhoto: {
    width: "100%",
    maxWidth: "500px",
    maxHeight: "500px",
  },
  link: {
    margin: "0",
    width: "503px",
    height: "28px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
    textDecorationLine: "underline",
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
