import React from "react";
import { Typography, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import { grey } from "@material-ui/core/colors";

import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

import useStyles from "./Content-jss";
import logo from "../../../assets/images/PlusLanding.svg";
import vision from "../../../assets/images/vision.svg";
import mission from "../../../assets/images/mission.svg";
import mef from "../../../assets/images/mef.png";
import techyon from "../../../assets/images/techyon.png";

import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  EVENT_LABELS,
} from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";

export default function Content() {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.landing}>
        <Grid container spacing={5} className={classes.landingContent}>
          <Grid item md={1}></Grid>
          <Grid
            container
            item
            md={5}
            sm={12}
            className={classes.landingIntroContainer}
          >
            <div className={classes.landingIntro}>
              <Typography className={classes.title}>Tech+ UW</Typography>
              <Typography className={classes.desc}>
                At Tech+, we value diversity, equity and inclusion because we
                believe that tech is for everyone.
              </Typography>
              <Grid item xs={12} className={classes.media}>
                <Link
                  href="https://twitter.com/techplusuw"
                  aria-label="Tech Plus Twitter"
                  target="_blank"
                  onClick={() =>
                    GAnalyticsPostEvent(
                      EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                      EVENT_ACTIONS.CLICK,
                      EVENT_LABELS.BANNER_TWITTER_ICON
                    )
                  }
                >
                  <Avatar className={classes.avatar1}>
                    <TwitterIcon style={{ color: grey[50] }} />
                  </Avatar>
                </Link>
                <Link
                  href={"mailto:techplusuw@gmail.com"}
                  aria-label="Tech Plus Email"
                  target="_blank"
                  onClick={() =>
                    GAnalyticsPostEvent(
                      EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                      EVENT_ACTIONS.CLICK,
                      EVENT_LABELS.BANNER_MAIL_ICON
                    )
                  }
                >
                  <Avatar className={classes.avatar2}>
                    <EmailIcon style={{ color: grey[50] }} />
                  </Avatar>
                </Link>
                <Link
                  href={"https://www.facebook.com/techplusuw/"}
                  aria-label="Tech Plus Facebook"
                  target="_blank"
                  onClick={() =>
                    GAnalyticsPostEvent(
                      EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                      EVENT_ACTIONS.CLICK,
                      EVENT_LABELS.BANNER_FACEBOOK_ICON
                    )
                  }
                >
                  <Avatar className={classes.avatar3}>
                    <FacebookIcon style={{ color: grey[50] }} />
                  </Avatar>
                </Link>
                <Link
                  href={"https://www.instagram.com/techplusuw/"}
                  aria-label="Tech Plus Facebook"
                  target="_blank"
                  onClick={() =>
                    GAnalyticsPostEvent(
                      EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                      EVENT_ACTIONS.CLICK,
                      EVENT_LABELS.BANNER_INSTAGRAM_ICON
                    )
                  }
                >
                  <Avatar className={classes.avatar4}>
                    <InstagramIcon style={{ color: grey[50] }} />
                  </Avatar>
                </Link>
              </Grid>
              <Grid item xs={12} className={classes.buttons}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.button1}
                  href="#upcoming_events"
                  onClick={() =>
                    GAnalyticsPostEvent(
                      EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                      EVENT_ACTIONS.CLICK,
                      EVENT_LABELS.BANNER_UPCOMING_EVENTS_BTN
                    )
                  }
                >
                  Upcoming Events
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.button2}
                  href="#mailing"
                  onClick={() =>
                    GAnalyticsPostEvent(
                      EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
                      EVENT_ACTIONS.CLICK,
                      EVENT_LABELS.BANNER_MAILING_LIST_BTN
                    )
                  }
                >
                  Mailing List
                </Button>
              </Grid>
            </div>
          </Grid>
          <Grid container item md={5} sm={12} className={classes.imgContainer}>
            <img src={logo} className={classes.img} alt="Tech+ UW" />
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <h2 className={classes.about}>About Us</h2>
          <p className={classes.about_t}>
            We believe that as students, we have the responsibility to do more
            for underrepresented communities at the University of Waterloo.
            Tech+ is a student organization that aims to support students, from
            all backgrounds, to be confident, knowledgeable, and empowered to
            pursue a career in the tech industry.
          </p>
        </Grid>
        <Grid item xs={12} md={7} className={classes.visionContainer}>
          <h2 className={classes.vision}>Vision</h2>
          <p className={classes.vision_t}>
            To cultivate a more inclusive and diverse tech community at
            UWaterloo by making resources more accessible to the community and
            students in need.
          </p>
        </Grid>
        <Grid item xs={12} md={5} className={classes.visionMissionPic}>
          <img src={vision} alt="vision" />
        </Grid>
        <Grid item xs={12} md={6} className={classes.visionMissionPic}>
          <img src={mission} alt="mission" />
        </Grid>
        <Grid item xs={12} md={6} className={classes.mission}>
          <h2 className={classes.vision}>Mission</h2>
          <p className={classes.vision_t}>
            Helping to create resources and events for students looking to break
            into tech. We believe that no part of your identity should hinder
            your access to opportunities in tech, nor should it be a roadblock
            on your path to success.
          </p>
        </Grid>
        <Grid item xs={12}>
          <h2 className={classes.sponsors}>Sponsors</h2>
          <p className={classes.sponsors_t}>
            We want to thank our sponsors who help make our goals possible:
          </p>
        </Grid>
        <Grid item xs={12} className={classes.sponsorPic}>
          <a href="https://uwaterloo.ca/math-endowment-fund/">
            <img
              src={mef}
              alt="mef"
              height="110px"
              style={{ paddingTop: "16px", margin: "0 34px" }}
            />
          </a>
          <a href="https://techyon.org/">
            <img className={classes.sponsorLogo} src={techyon} alt="techyon" />
          </a>
        </Grid>
      </Grid>
    </>
  );
}
