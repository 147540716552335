import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  programContainerLeft: {
    display: "flex",
    alignItems: "flex-end",
    alignSelf: "flex-start",
    flexDirection: "row-reverse",
    textAlign: "right",
    "@media (max-width: 1250px)": {
      flexDirection: "column",
      alignSelf: "center",
      alignItems: "center",
      textAlign: "left",
    },
  },
  programContainerRight: {
    display: "flex",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    "@media (max-width: 1250px)": {
      flexDirection: "column",
      alignSelf: "center",
      alignItems: "center",
      textAlign: "left",
    },
  },
  programCardLeft: {
    boxSizing: "border-box",
    padding: "30px",
    boxShadow: "0 4px 4px 3px rgba(0,0,0,0.25)",
    maxWidth: "650px",
    position: "relative",
    right: "80px",
    bottom: "20px",
    "@media (max-width: 1250px)": {
      right: "0",
    },
  },
  programCardRight: {
    boxSizing: "border-box",
    padding: "30px",
    boxShadow: "0 4px 4px 3px rgba(0,0,0,0.25)",
    maxWidth: "650px",
    position: "relative",
    left: "80px",
    bottom: "20px",
    "@media (max-width: 1250px)": {
      left: "0",
    },
  },
  programTitle: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "54px",
  },
  programDesc: {
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "27px",
  },
  programImg: {
    maxWidth: "700px",
    "@media (max-width: 700px)": {
      width: "95vw",
    },
  },
  programButton: {
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "25px",
    fontSize: "16px",
  },
}));

export default useStyles;
