import React from "react";

import Grid from "@material-ui/core/Grid";
import useStyles from "./Profile-jss";
import { techPlusTeamMembers } from "./config";
import LeagueDisplay from "../TeamDisplay/TeamDisplay";

export default function Profile() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Meet Our Team</h1>
      <p className={classes.desc}>
        We are a dedicated group of students who work toward the common goal of
        building the tech community at UW for you
      </p>
      <Grid container spacing={0} className={classes.grid}>
        <LeagueDisplay league={techPlusTeamMembers} />
      </Grid>
    </div>
  );
}
