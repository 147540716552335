import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  dropzone: {
    width: "500px",
    height: "250px",
    borderStyle: "dashed",
    borderWidth: "5px",
    margin: "10px 10px 10px 10px",
  },
  avatar: {
    width: theme.spacing(60),
    height: theme.spacing(60),
    marginBottom: "20px",
  },
}));
export default useStyles;
