import React from "react";
import ResourcesDisplay from "../ResourcesDisplay";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import useStyles from "./Resources-jss";

import w21TeamStateImg from "../../../assets/images/W22TeamStatement.svg";

import {
  EVENT_CATEGORIES,
  EVENT_ACTIONS,
  EVENT_LABELS,
} from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";

const resourceTitleParser = (resourceTitle) =>
  resourceTitle.toLowerCase().replaceAll(" ", "_").replaceAll("/", "_");

export default function Resources() {
  const classes = useStyles();

  const resources = [
    {
      link:
        "https://drive.google.com/file/d/18mCKk31vRv0cwoOYYRlaeix-cY3XSz7B/view",
      text: "Navigating race in Canadian Workplaces",
      linkClickEventTrackingLabel: EVENT_LABELS.DNI_LINK_CANADIAN_WORKPLACES,
    },
    {
      link:
        "https://lgbtqexperiment.com/2018/10/28/should-i-use-lgbt-lgbtq-lgbtq-lgbtqqia-or-something-else/",
      text: "Should I use LGBT, LGBTQ, LGBTQ+, LGBTQQIA, or something else?",
      linkClickEventTrackingLabel: EVENT_LABELS.DNI_LINK_LGBTQ_SHOULD_I_USE,
    },
    {
      link:
        "https://www.techrepublic.com/article/5-eye-opening-statistics-about-minorities-in-tech/",
      text: "5 eye-opening statistics about minorities in tech ",
      linkClickEventTrackingLabel: EVENT_LABELS.DNI_LINK_MINORITIES_IN_TECH,
    },
    {
      link:
        "https://www.wired.com/story/five-years-tech-diversity-reports-little-progress/",
      text: "Five Years of Tech Diversity Reports—and Little Progress",
      linkClickEventTrackingLabel: EVENT_LABELS.DNI_LINK_FIVE_YEARS_REPORT,
    },
  ];

  const leftGetInvolved = [
    {
      link: "https://www.facebook.com/uwdsc",
      text: "UW Data Science Club",
    },
    {
      link: "https://www.facebook.com/uwaterloopm",
      text: "UW PM",
    },
    {
      link: "https://www.instagram.com/uwwistem/",
      text: "UW WiSTEM",
    },
    {
      link: "https://www.instagram.com/uwaterloowie/",
      text: "UW Women in Engineering",
    },
    {
      link: "https://www.watonomous.ca/",
      text: "WATONOMOUS",
    },
    {
      link: "https://www.facebook.com/uw.computerscienceclub",
      text: "Waterloo Computer Science Club",
    },
    {
      link: "https://www.facebook.com/uwaterloowics",
      text: "Waterloo Women in Computer Science",
    },
    {
      link: "https://www.facebook.com/uwuxwaterloo",
      text: "UW/UX",
    },
    {
      link: "https://uwblueprint.org/",
      text: "UW Blueprint",
    },
  ];

  const rightGetInvolved = [
    {
      link: "https://www.facebook.com/asauw",
      text: "African Students Association",
    },
    {
      link: "https://www.facebook.com/UWEngiQueers/",
      text: "UW EngiQueers",
    },
    {
      link: "https://www.facebook.com/uwbase",
      text: "Black Association for Student Expression",
    },
    {
      link: "https://www.facebook.com/QTPOC-KW-930748673793249",
      text: "QTPOC KW",
    },
    {
      link: "https://www.facebook.com/nsbe.waterloo/",
      text: "National Society of Black Engineers",
    },
  ];

  return (
    <div className={classes.root}>
      <ResourcesDisplay />
      <div className={classes.diversityInclusion}>
        <h1 className={classes.subtitle}>Diversity and Inclusion</h1>
        <Grid container className={classes.resourcesContainer}>
          <Grid item lg={6} md={12}>
            <p className={classes.content}>
              At Tech+, we aim to promote diversity and inclusion at UWaterloo
              by establishing meaningful relationships with underrepresented
              communities and learning what barriers, needs, and gaps exist.
              Additionally, we want to dedicate time to empower underrepresented
              individuals to encourage their involvement with Tech+ initiatives.
            </p>
            <p className={classes.content}>
              Here are some useful resources and articles to learn about
              Diversity and Inclusion.
            </p>
            {resources.map((resource) => (
              <Link
                href={resource.link}
                target="_blank"
                className={classes.link}
                onClick={() =>
                  GAnalyticsPostEvent(
                    EVENT_CATEGORIES.LANDING_PAGE_RESOURCES,
                    EVENT_ACTIONS.CLICK,
                    resource.linkClickEventTrackingLabel
                  )
                }
              >
                {resource.text}
                <br />
                <br />
              </Link>
            ))}
          </Grid>
          <Grid item lg={6} md={12} className={classes.imageContainer}>
            <div>
              <img
                src={w21TeamStateImg}
                alt="TeamStatement"
                className={classes.statementPhoto}
              />
            </div>
          </Grid>
        </Grid>
        <h1 className={classes.subtitle}>Get Involved</h1>
        <Grid container spacing={4} className={classes.getInvolvedContainer}>
          <Grid item lg={6} md={12}>
            <p className={classes.content}>
              There are lots of other clubs and organizations out there to
              provide you help and support! Feel free to check out these
              opportunities.
            </p>
          </Grid>
          <Grid item lg={6} md={12}>
            <Grid container className={classes.linkContainer}>
              <Grid item lg={5} sm={12}>
                <br />
                {leftGetInvolved.map((resource) => (
                  <Link
                    href={resource.link}
                    target="_blank"
                    className={classes.link}
                    onClick={() =>
                      GAnalyticsPostEvent(
                        EVENT_CATEGORIES.LANDING_PAGE_RESOURCES,
                        EVENT_ACTIONS.CLICK,
                        `${
                          EVENT_LABELS.GET_INVOLVED_LINK
                        }_${resourceTitleParser(resource.text)}`
                      )
                    }
                  >
                    {resource.text}
                    <br />
                    <br />
                  </Link>
                ))}
              </Grid>
              <Grid item lg={2} sm={12} className={classes.centeredDivider}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                item
                lg={5}
                sm={12}
                className={classes.rightGetInvolvedLinks}
              >
                <br />
                {rightGetInvolved.map((resource) => (
                  <Link
                    href={resource.link}
                    target="_blank"
                    className={classes.link}
                    onClick={() =>
                      GAnalyticsPostEvent(
                        EVENT_CATEGORIES.LANDING_PAGE_RESOURCES,
                        EVENT_ACTIONS.CLICK,
                        `${
                          EVENT_LABELS.GET_INVOLVED_LINK
                        }_${resourceTitleParser(resource.text)}`
                      )
                    }
                  >
                    {resource.text}
                    <br />
                    <br />
                  </Link>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
