import React, { useContext, useEffect } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import PulseLoader from "react-spinners/PulseLoader";

import FeatureFlagContext from "../../FeatureFlagContext";
import { EVENT_CATEGORIES, EVENT_ACTIONS, EVENT_LABELS } from "../../constants";
import { GAnalyticsPostEvent } from "../../utils";
import { useStore } from "../../utils/store";
import { FEATURE_FLAGS } from "../../constants";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NavbarTabs = (props) => {
  const flagsmithFFs = useContext(FeatureFlagContext);
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
  } = useAuth0();

  const setUserRole = useStore((s) => s.setUserRole);
  const userRole = useStore((s) => s.userRole);

  useEffect(() => {
    if (isAuthenticated) {
      const getInfo = async () => {
        const token = await getAccessTokenSilently();

        var decoded = jwt_decode(token);

        setUserRole(decoded["https://www.techplusuw.org/roles"][0]);
      };

      getInfo();
    }
  }, [isAuthenticated, setUserRole, getAccessTokenSilently]);

  if (flagsmithFFs.isLoading || isLoading) {
    return <PulseLoader color="#FFFFFF" loading size={4} />;
  }

  return (
    <Tabs
      value={props.currentTab}
      onChange={(_, val) => props.setCurrentTab(val)}
      orientation={props.tabOrientation}
      TabIndicatorProps={{ style: { background: "secondary" } }}
    >
      <Tab
        label="About"
        {...a11yProps(0)}
        className={props.tabClassname}
        onClick={() =>
          GAnalyticsPostEvent(
            EVENT_CATEGORIES.LANDING_PAGE_ABOUT,
            EVENT_ACTIONS.CLICK,
            EVENT_LABELS.TAB_NAVIGATION
          )
        }
      />
      <Tab
        label="Initiatives"
        {...a11yProps(1)}
        className={props.tabClassname}
        onClick={() =>
          GAnalyticsPostEvent(
            EVENT_CATEGORIES.LANDING_PAGE_INITIATIVES,
            EVENT_ACTIONS.CLICK,
            EVENT_LABELS.TAB_NAVIGATION
          )
        }
      />
      <Tab
        label="Impact"
        {...a11yProps(2)}
        className={props.tabClassname}
        onClick={() =>
          GAnalyticsPostEvent(
            EVENT_CATEGORIES.LANDING_PAGE_IMPACT,
            EVENT_ACTIONS.CLICK,
            EVENT_LABELS.TAB_NAVIGATION
          )
        }
      />
      <Tab
        label="Resources"
        {...a11yProps(3)}
        className={props.tabClassname}
        onClick={() =>
          GAnalyticsPostEvent(
            EVENT_CATEGORIES.LANDING_PAGE_RESOURCES,
            EVENT_ACTIONS.CLICK,
            EVENT_LABELS.TAB_NAVIGATION
          )
        }
      />
      {!flagsmithFFs.loading &&
        flagsmithFFs.allFeatureFlags[FEATURE_FLAGS.SHOW_SIGNUP_LOGIN_NAVTAB]
          .enabled &&
        isAuthenticated &&
        userRole !== "Mentor" && (
          <Tab
            label="Mentor Profiles"
            {...a11yProps(4)}
            className={props.tabClassname}
            onClick={() =>
              GAnalyticsPostEvent(
                EVENT_CATEGORIES.PORTAL_MENTORS,
                EVENT_ACTIONS.CLICK,
                EVENT_LABELS.TAB_NAVIGATION
              )
            }
          />
        )}

      {!flagsmithFFs.loading &&
        flagsmithFFs.allFeatureFlags[FEATURE_FLAGS.SHOW_SIGNUP_LOGIN_NAVTAB]
          .enabled &&
        isAuthenticated &&
        userRole === "Mentor" && (
          <Tab
            label="My Profile"
            {...a11yProps(5)}
            className={props.tabClassname}
            onClick={() =>
              GAnalyticsPostEvent(
                EVENT_CATEGORIES.PORTAL_PROFILE,
                EVENT_ACTIONS.CLICK,
                EVENT_LABELS.TAB_NAVIGATION
              )
            }
          />
        )}

      {!flagsmithFFs.loading &&
        flagsmithFFs.allFeatureFlags[FEATURE_FLAGS.SHOW_SIGNUP_LOGIN_NAVTAB]
          .enabled && (
          <Tab
            label={isAuthenticated ? "Logout" : "Login"}
            {...a11yProps(6)}
            className={props.tabClassname}
            onClick={() => {
              isAuthenticated
                ? logout({ returnTo: window.location.origin })
                : loginWithRedirect();
            }}
          />
        )}
    </Tabs>
  );
};

export default NavbarTabs;
