import React from "react";
import classNames from "classnames";
import BannerCard from "./BannerCard";
import useStyles from "./Banner-jss";
import eventImg from "../../../assets/images/impact/events.png";
import eventAttendImg from "../../../assets/images/impact/event_attend.png";
import communityImg from "../../../assets/images/impact/community.png";
import firstYearImg from "../../../assets/images/impact/first_year.png";
import mentorshipImg from "../../../assets/images/impact/mentorship.png";
import coffeeImg from "../../../assets/images/impact/coffee.png";

export default function Banner() {
  const classes = useStyles();
  const cards = [
    {
      containerClassname: classes.eventContainer,
      count: "23",
      desc: "Events",
      img: eventImg,
      boxStyleClassname: classNames(classes.boxStyle, classes.eventCardWidth),
    },
    {
      containerClassname: classes.firstYearContainer,
      count: "500+",
      desc: "First-years reached",
      img: firstYearImg,
      boxStyleClassname: classNames(
        classes.boxStyle,
        classes.firstYearCardWidth
      ),
    },
    {
      containerClassname: classes.eventAttendContainer,
      count: "1800+",
      desc: "Event Attendees",
      img: eventAttendImg,
      boxStyleClassname: classNames(
        classes.boxStyle,
        classes.eventAttendCardWidth
      ),
    },
    {
      containerClassname: classes.mentorshipContainer,
      count: "573",
      desc: "Mentorship Pairs",
      img: mentorshipImg,
      boxStyleClassname: classNames(
        classes.boxStyle,
        classes.mentorshipCardWidth
      ),
    },
    {
      containerClassname: classes.coffeeContainer,
      count: "980",
      desc: "Coffee Chat Pairings",
      img: coffeeImg,
      boxStyleClassname: classNames(classes.boxStyle, classes.coffeeCardWidth),
    },
    {
      containerClassname: classes.communityContainer,
      count: "1",
      desc: "Community",
      img: communityImg,
      boxStyleClassname: classNames(
        classes.boxStyle,
        classes.communityCardWidth
      ),
    },
  ];

  return (
    <div className={classes.root}>
      {cards.map((card, index) => {
        return (
          <div className={card.containerClassname} index={index}>
            <BannerCard
              count={card.count}
              desc={card.desc}
              img={card.img}
              boxStyle={card.boxStyleClassname}
            />
          </div>
        );
      })}
    </div>
  );
}
