import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import useStyles from "./EventsSection-jss";

export default function EventsSection({ events }) {
  const classes = useStyles();
  const listEvents = events.map((event) => (
    <ListItem disableGutters>{event}</ListItem>
  ));

  return (
    <List dense className={classes.eventSection}>
      {listEvents}
    </List>
  );
}
