import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cardActionArea: {
    padding: "5%",
  },
  card: {
    height: "100%",
    padding: "29px",
    borderRadius: 0,
    border: "#c0c1c2 1px solid",
  },
  media: {
    height: "50%",
    width: "auto",
    marginBottom: "6%",
    "@media (max-width: 1040px)": {
      height: "200px",
    },
  },
  title: {
    fontWeight: "600",
  },
  desc: {
    "@media (min-width: 1040px)": {
      fontSize: "1.5vw",
    },
    "@media (min-width: 1200px)": {
      fontSize: "1.3vw",
    },
    "@media (min-width: 1250px)": {
      fontSize: "1.2vw",
    },
  },
});

export default useStyles;
