// Pictures
// Core Team
import Enoch from "../../../assets/images/orgTeam/core/EnochTin.jpg";
import Felicity from "../../../assets/images/orgTeam/core/FelicityLi.png";
import Lynne from "../../../assets/images/orgTeam/core/LynneDai.jpg";
import Navya from "../../../assets/images/orgTeam/core/Navya.jpg";
import Tejesh from "../../../assets/images/orgTeam/core/TejeshArujuna.jpg";
import Aditi from "../../../assets/images/orgTeam/core/AditiSandhu.jpeg";

// Logistics Team
import Alia from "../../../assets/images/orgTeam/logistics/AliaCai.jpg";
import Ammielle from "../../../assets/images/orgTeam/logistics/AmmielleWamboBecker.jpg";
import Jia from "../../../assets/images/orgTeam/logistics/JiaHuang.jpeg";
import Kevin from "../../../assets/images/orgTeam/logistics/KevinDaniel.jpeg";
import Scarlett from "../../../assets/images/orgTeam/logistics/ScarlettJet.jpg";

// Development
import Achchala from "../../../assets/images/orgTeam/development/AchchalaDeepan.png";
import Cindy from "../../../assets/images/orgTeam/development/CindyLi.jpg";
import Edmond from "../../../assets/images/orgTeam/development/EdmondLi.jpeg";
import Felice from "../../../assets/images/orgTeam/development/FeliceMa.jpg";
import Jewel from "../../../assets/images/orgTeam/development/JewelReese.jpeg";
import Karam from "../../../assets/images/orgTeam/development/KaramHammal.jpg";
import Kripa from "../../../assets/images/orgTeam/development/KripaPokhrel.jpg";
import Saumya from "../../../assets/images/orgTeam/development/SaumyaDutta.jpg";
import Sum from "../../../assets/images/orgTeam/development/SumLiu.jpg";

// Research
import Ananya from "../../../assets/images/orgTeam/research/AnanyaOhrie.png";
import Indy from "../../../assets/images/orgTeam/research/IndyNg.jpg";
import Jacob from "../../../assets/images/orgTeam/research/JacobYan.png";
import Judy from "../../../assets/images/orgTeam/research/JudyYang.jpeg";
import Kerushani from "../../../assets/images/orgTeam/research/KerushaniSivaneswaran.jpg";
import Shireen from "../../../assets/images/orgTeam/research/ShireenRajora.jpeg";
import Syra from "../../../assets/images/orgTeam/research/SyraSingh.jpg";

// Outreach
import Ariel from "../../../assets/images/orgTeam/outreach/ArielWei.png";
import Armin from "../../../assets/images/orgTeam/outreach/ArminZubair.jpg";
import Aryan from "../../../assets/images/orgTeam/outreach/AryanVijayan.jpeg";
import Daksh from "../../../assets/images/orgTeam/outreach/DakshVerma.jpeg";
import Khushi from "../../../assets/images/orgTeam/outreach/KhushiAdukia.jpeg";
import Namrah from "../../../assets/images/orgTeam/outreach/NamrahHasan.jpg";

// 16 Weeks Team
import Ben from "../../../assets/images/orgTeam/16weeks/BenChung.jpeg";
import Bilal from "../../../assets/images/orgTeam/16weeks/BilalAli.png";
import Inayah from "../../../assets/images/orgTeam/16weeks/InayahChaudhary.jpg";
import Kabir from "../../../assets/images/orgTeam/16weeks/KabirSathe.jpeg";

// Mentorship Team
import Kim from "../../../assets/images/orgTeam/mentorship/KimHoang.jpeg";
import Sebastian from "../../../assets/images/orgTeam/mentorship/SebastianValencia.jpeg";
import Default from "../../../assets/images/Coffee.png";

export const techPlusTeamMembers = [
  {
    name: "Core",
    divisions: [
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Lynne",
            role: "Co-Director",
            photoUrl: Lynne,
            linkedInUrl: "https://www.linkedin.com/in/lynnedai",
          },
          {
            name: "Tejesh Arujuna",
            role: "Co-Director",
            photoUrl: Tejesh,
            linkedInUrl: "https://www.linkedin.com/in/tejesharujuna/",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Enoch Tin",
            role: "Finance Director",
            photoUrl: Enoch,
            linkedInUrl: "https://www.linkedin.com/in/enoch-tin/",
          },
          {
            name: "Navya",
            role: "Internal Culture Manager",
            photoUrl: Navya,
            linkedInUrl: "https://www.linkedin.com/in/navya-6a2698275/",
          },
          {
            name: "Felicty Li",
            role: "Internal Operations Manager",
            photoUrl: Felicity,
            linkedInUrl: "https://www.linkedin.com/in/felicity-li/",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Aditi Sandhu",
            role: "Advisor",
            photoUrl: Aditi,
            linkedInUrl: "https://www.linkedin.com/in/aditisandhu/",
          },
          {
            name: "Barton Lu",
            role: "Advisor",
            photoUrl: Default,
            linkedInUrl: "https://www.linkedin.com/in/bartonlu/",
          },
        ],
      },
    ],
  },
  {
    name: "Logistics",
    divisions: [
      {
        divisionName: "Resources",
        divisionMembers: [
          {
            name: "Scarlett Jet",
            role: "Logistics Co-Lead",
            photoUrl: Scarlett,
            linkedInUrl: "https://www.linkedin.com/in/scarlett-j-203459203/",
          },
          {
            name: "Ammielle Wambo Becker",
            role: "Logistics Co-Lead",
            photoUrl: Ammielle,
            linkedInUrl: "https://www.linkedin.com/in/ammielle-wambo-becker/",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Alia Cai",
            role: "Logistics Organizer",
            photoUrl: Alia,
            linkedInUrl: "https://www.linkedin.com/in/alia-cai-9795752a5/",
          },
          {
            name: "Kevin Daniel",
            role: "Advisor",
            photoUrl: Kevin,
            linkedInUrl: "https://www.linkedin.com/in/kevin-daniel-288a131b5/",
          },
          {
            name: "Jia Huang",
            role: "Logistics Organizer",
            photoUrl: Jia,
            linkedInUrl: "https://www.linkedin.com/in/yi-jia-huang/",
          },
        ],
      },
      {
        divisionName: "Mentorship",
        divisionMembers: [
          {
            name: "Sebastian Valencia",
            role: "Logistic Organizer",
            photoUrl: Sebastian,
            linkedInUrl: "https://www.linkedin.com/in/svalenci/",
          },
          {
            name: "Kim Hoang",
            role: "Advisor",
            photoUrl: Kim,
            linkedInUrl: "https://www.linkedin.com/in/kimmyhoang/",
          },
        ],
      },
    ],
  },
  {
    name: "Development",
    divisions: [
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Saumya Dutta",
            role: "Engineering Manager",
            photoUrl: Saumya,
            linkedInUrl: "https://www.linkedin.com/in/saumya-dutta/",
          },
          {
            name: "Jewel Reese",
            role: "Product Manager",
            photoUrl: Jewel,
            linkedInUrl: "https://www.linkedin.com/in/jewelreese",
          },
          {
            name: "Achchala Deepan",
            role: "Techincal Lead",
            photoUrl: Achchala,
            linkedInUrl: "https://www.linkedin.com/in/achchala/",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Karam Hammal",
            role: "Fullstack Developer",
            photoUrl: Karam,
            linkedInUrl: "https://www.linkedin.com/in/karamhammal/",
          },
          {
            name: "Cindy Li",
            role: "Fullstack Developer",
            photoUrl: Cindy,
            linkedInUrl: "https://www.linkedin.com/in/cindy-hj-li/",
          },
          {
            name: "Edmond Li",
            role: "Fullstack Developer",
            photoUrl: Edmond,
            linkedInUrl: "https://www.linkedin.com/in/edmond-li2004/",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Sum Liu",
            role: "Product Designer",
            photoUrl: Sum,
            linkedInUrl: "https://www.linkedin.com/in/sumalfka-liu/",
          },
          {
            name: "Kripa Pokhrel",
            role: "Product Designer",
            photoUrl: Kripa,
            linkedInUrl: "https://www.linkedin.com/in/kripapokhrel/",
          },
          {
            name: "Felice Ma",
            role: "Product Designer",
            photoUrl: Felice,
            linkedInUrl: "https://www.linkedin.com/in/felicema217/",
          },
        ],
      },
    ],
  },
  {
    name: "Research",
    divisions: [
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Jacob Yan",
            role: "Research Lead",
            photoUrl: Jacob,
            linkedInUrl: "https://www.linkedin.com/in/jacob-yan/",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Judy Yang",
            role: "Strategic Data Analyst",
            photoUrl: Judy,
            linkedInUrl: "https://www.linkedin.com/in/jjudyyang/",
          },
          {
            name: "Ananya Ohrie",
            role: "Strategic Data Analyst",
            photoUrl: Ananya,
            linkedInUrl: "https://www.linkedin.com/in/ananya-ohrie/",
          },
          {
            name: "Syra Singh",
            role: "Strategic Data Analyst",
            photoUrl: Syra,
            linkedInUrl: "https://www.linkedin.com/in/syra-singh",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Indy Ng",
            role: "Researcher",
            photoUrl: Indy,
            linkedInUrl: "https://www.linkedin.com/in/indy-ng/",
          },
          {
            name: "Kerushani Sivaneswaran",
            role: "Researcher",
            photoUrl: Kerushani,
            linkedInUrl:
              "https://www.linkedin.com/in/kerushani-sivaneswaran-80139b228/",
          },
          {
            name: "Shireen Rajora",
            role: "Researcher",
            photoUrl: Shireen,
            linkedInUrl: "https://www.linkedin.com/in/shireenrajora/",
          },
        ],
      },
    ],
  },
  {
    name: "Outreach",
    divisions: [
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Daksh Verma",
            role: "Marketing Coordinator",
            photoUrl: Daksh,
            linkedInUrl: "https://www.linkedin.com/in/dakshv/",
          },
          {
            name: "Namrah Hasan",
            role: "Outreach Lead",
            photoUrl: Namrah,
            linkedInUrl: "https://www.linkedin.com/in/namrah-hasan-52941a1b0/",
          },
          {
            name: "Aryan Vijayan",
            role: "Marketing Coordinator",
            photoUrl: Aryan,
            linkedInUrl: "https://www.linkedin.com/in/aryan-vijayan-216961210/",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Armin Zubair",
            role: "Graphic Designer",
            photoUrl: Armin,
            linkedInUrl: "https://www.linkedin.com/in/armin-zubair",
          },
          {
            name: "Khushi Adukia",
            role: "Graphic Designer",
            photoUrl: Khushi,
            linkedInUrl: "https://linkedin.com/in/khushiadukia",
          },
          {
            name: "Ariel Wei",
            role: "Graphic Designer",
            photoUrl: Ariel,
            linkedInUrl: "https://www.linkedin.com/in/ariel-wei",
          },
        ],
      },
    ],
  },
  {
    name: "16 Weeks",
    divisions: [
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Inayah Chaudhary",
            role: "16 Weeks Lead",
            photoUrl: Inayah,
            linkedInUrl: "https://www.linkedin.com/in/inayah-chaudhary/",
          },
          {
            name: "Kabir Sathe",
            role: "Content Director",
            photoUrl: Kabir,
            linkedInUrl: "http://www.linkedin.com/in/kabirsathe",
          },
        ],
      },
      {
        divisionName: "",
        divisionMembers: [
          {
            name: "Bilal Ali",
            role: "Content Co-ordinator",
            photoUrl: Bilal,
            linkedInUrl: "https://www.linkedin.com/in/bilal-ali7",
          },
          {
            name: "Derek Li",
            role: "Content Co-ordinator",
            photoUrl: Default,
            linkedInUrl: "https://www.linkedin.com/in/d3li/",
          },
          {
            name: "Ben Chung",
            role: "Content Co-ordinator",
            photoUrl: Ben,
            linkedInUrl: "https://www.linkedin.com/in/benjaminrchung/",
          },
        ],
      },
    ],
  },
];
