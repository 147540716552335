import React, { useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import useStyles from "./MentorFilterBar-jss";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  AppBar,
  FormControl,
  Typography,
  Chip,
  Grid,
  Button,
  Box,
  IconButton,
  ListItem,
  Select,
  Drawer,
  Toolbar,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { getCategoryLabel } from "src/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, expertiseName, theme) {
  return {
    fontWeight: expertiseName.includes(name)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
    whiteSpace: "normal",
  };
}

export default function MentorFilterBar({
  isMobile,
  currentPage,
  callApi,
  termFilters,
  expertiseFilters,
  programFilters,
  loading,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [expertise, setExpertise] = React.useState([]);
  const [program, setProgram] = React.useState([]);
  const [term, setTerm] = React.useState([]);
  const [open, setOpen] = React.useState();
  const filterChangeApiCall = React.useRef(false);

  const fields = [
    {
      title: "Expertise",
      choices: Array.from(expertiseFilters).sort(),
    },
    {
      title: "Program",
      choices: Array.from(programFilters).sort(),
    },
    {
      title: "Term",
      choices: Array.from(termFilters).sort(),
    },
  ];

  const handleExpertiseChange = (event) => {
    setExpertise(event.target.value);
  };
  const handleProgramChange = (event) => {
    setProgram(event.target.value);
  };
  const handleTermChange = (event) => {
    setTerm(event.target.value);
  };

  useEffect(() => {
    // stop redundant api calls from page change
    filterChangeApiCall.current = true;
    callApi(expertise, program, term);
  }, [expertise, program, term, isMobile, callApi]);

  // call api for going to a new page w/out filter change
  useEffect(() => {
    if (
      !filterChangeApiCall.current ||
      (filterChangeApiCall.current && currentPage !== 1)
    ) {
      callApi(expertise, program, term, true, currentPage);
    }
    filterChangeApiCall.current = false;
  }, [currentPage, callApi, expertise, program, term]);

  const handleFilter = () => {
    handleDrawerClose();
    filterChangeApiCall.current = true;
    callApi(expertise, program, term);
  };

  const clearFilters = () => {
    setExpertise([]);
    setProgram([]);
    setTerm([]);
    handleFilter();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function FilterItem({ field }) {
    return (
      <ListItem>
        <Grid item className={classes.searchFields}>
          <FormControl className={classes.formControl}>
            <InputLabel color="primary" className={classes.label}>
              {field.title}
            </InputLabel>
            <Select
              className={classes.select}
              multiple
              displayEmpty
              value={
                field.title === "Expertise"
                  ? expertise
                  : field.title === "Program"
                  ? program
                  : term
              }
              onChange={
                field.title === "Expertise"
                  ? handleExpertiseChange
                  : field.title === "Program"
                  ? handleProgramChange
                  : handleTermChange
              }
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map(
                    (value) =>
                      !loading && (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      )
                  )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {field.choices.map((item) => {
                const itemName = getCategoryLabel(item, field.title);
                return (
                  <MenuItem
                    key={itemName}
                    value={itemName}
                    style={getStyles(itemName, expertise, theme)}
                  >
                    {itemName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </ListItem>
    );
  }

  return (
    <>
      <Box
        display={{ xs: "none", md: "block" }}
        className={classes.fullWidthRoot}
      >
        <Grid container position="static" className={classes.bar}>
          <div className={classes.wrapper}>
            <Grid item className={classes.header}>
              Search by:
            </Grid>
            {fields.map((field) => (
              <FilterItem field={field} />
            ))}
            <Button
              variant="outlined"
              style={{ margin: 4 }}
              onClick={clearFilters}
            >
              Clear
            </Button>
          </div>
        </Grid>
      </Box>
      <Box className={classes.mobileRoot} display={{ xs: "block", md: "none" }}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
            />
            <div>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                aria-haspopup="true"
                onClick={handleDrawerOpen}
              >
                <FilterListIcon />
              </IconButton>
              <Drawer
                className={classes.drawer}
                anchor="right"
                open={open}
                onClose={handleDrawerClose}
              >
                <Box className={classes.drawerContainer}>
                  <Box className={classes.topBar}>
                    <Typography className={classes.title}>Filter</Typography>
                    <Button
                      className={classes.clearButton}
                      onClick={clearFilters}
                    >
                      Clear
                    </Button>
                  </Box>
                  <div height="auto">
                    <Box className={classes.drawerWrapper}>
                      {fields.map((field) => (
                        <FilterItem field={field} />
                      ))}
                    </Box>
                  </div>
                  <Button
                    className={classes.setFiltersButton}
                    onClick={handleFilter}
                  >
                    Set Filters
                  </Button>
                </Box>
              </Drawer>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
