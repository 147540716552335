import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5%",
    height: "20%",
    display: "grid",
    gridTemplateColumns: "repeat(15, 1fr)",
    gridTemplateRows: "repeat(11, 12%)",
  },
  eventContainer: {
    gridColumnEnd: "span 6",
    gridRowEnd: "span 4",
  },
  firstYearContainer: {
    gridColumnEnd: "span 9",
    gridRowEnd: "span 5",
    verticalAlign: "top",
  },
  eventAttendContainer: {
    gridColumnEnd: "span 6",
    gridRowEnd: "span 4",
  },
  mentorshipContainer: {
    gridColumnEnd: "span 5",
    gridRowEnd: "span 6",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  coffeeContainer: {
    gridColumnEnd: "span 4",
    gridRowEnd: "span 6",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  communityContainer: {
    gridColumnEnd: "span 6",
    gridRowEnd: "span 3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  boxStyle: {
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  eventCardWidth: {
    width: "55%",
  },
  firstYearCardWidth: {
    width: "35%",
  },
  eventAttendCardWidth: {
    width: "55%",
  },
  mentorshipCardWidth: {
    width: "100%",
  },
  coffeeCardWidth: {
    width: "70%",
  },
  communityCardWidth: {
    width: "60%",
  },
}));

export default useStyles;
