import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  portalTitleStyles: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "36px",
    background:
      "linear-gradient(120deg, rgba(139, 198, 119, 0.5) 0%, rgba(139, 198, 119, 0.5) 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 40%",
    backgroundPosition: "0 90%",
  },
  mentorProfileTitle: {
    maxWidth: "180px",
    paddingLeft: "1%",
  },
  funFactsTitle: {
    paddingLeft: "1%",
    paddingRight: "1%",
  },
  button: {
    margin: "20px",
    marginRight: "0px",
    borderRadius: 0,
  },
  name: {
    margin: "auto",
    padding: "7px 0",
    fontWeight: "600",
  },
  textField: {
    maxWidth: "700px",
    height: "6",
    borderRadius: 0,
    width: "160%",
    "@media (max-width: 1400px)": {
      width: "100%",
    },
  },
  select: {
    borderRadius: 0,
    maxWidth: "700px",
    height: "6",
  },
  formSection: {
    padding: "7px 0px",
  },
  longBox: {
    width: "100%",
    padding: "7px 0px",
  },
  longInput: {
    borderRadius: 0,
    width: "100%",
    marginBottom: "10px",
  },
  bottomMargin: {
    marginBottom: "20px",
  },
  privateProfileFormat: {
    padding: "7px 0",
  },
  privateProfileSwitchFormat: {
    padding: "7px 0",
  },
  chip: {
    margin: 9,
    borderRadius: 0,
  },
  errorText: {
    fontSize: "0.75rem",
    margin: "3px 14px",
    fontWeight: "400",
    lineHeight: "1.66",
    color: "#F44336",
  },
}));
export const customStyles = {
  control: (styles, state) => ({
    ...styles,
    borderRadius: 0,
    borderColor:
      state.selectProps.required && state.selectProps.error
        ? "#F44336"
        : "#FFFFFF",
  }),
};

export default useStyles;
