import React from "react";
import useStyles from "./Pagination-jss";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import Typography from "@material-ui/core/Typography";

export default function Pagination(props) {
  var numberOfPages = props.numberOfPages;
  var sendPage = props.sendPage;
  const pages = [];
  const classes = useStyles();
  const [currentPage, setPage] = React.useState(1);

  function createArray() {
    if (!numberOfPages) {
      numberOfPages = 1;
    }
    var i = 0;
    while (++i <= numberOfPages) {
      pages.push(i);
    }
  }

  React.useEffect(() => {
    sendPage(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSelect = (event) => {
    setPage(event.target.value);
  };

  const handleNext = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= numberOfPages) {
      setPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    const prevPage = currentPage - 1;
    if (prevPage > 0) {
      setPage(prevPage);
    }
  };

  return (
    <Grid container direction="row" className={classes.bar}>
      <Grid item>
        <Select
          className={classes.select}
          defaultValue={1}
          value={currentPage}
          onChange={handleSelect}
          variant="outlined"
        >
          {createArray()}
          {pages.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Typography className={classes.text}>
          {"of " + numberOfPages + " pages "}
        </Typography>
      </Grid>

      <IconButton
        className={classes.iconButton}
        disabled={currentPage === 1}
        onClick={handlePrev}
      >
        <ChevronLeftRoundedIcon size="large" />
      </IconButton>

      <IconButton
        className={classes.iconButton}
        disabled={currentPage === numberOfPages}
        onClick={handleNext}
      >
        <ChevronRightRoundedIcon size="large" />
      </IconButton>
    </Grid>
  );
}
