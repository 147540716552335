import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    left: "0%",
    right: "0%",
    top: "0%",
    bottom: "50%",
  },
  bar: {
    background: theme.palette.primary.main,
    position: "sticky",
    top: "0",
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "6%",
    marginRight: "6%",
    maxWidth: "100%",
  },
  menuButton: {
    marginRight: "5%",
  },
  tabVertical: {
    minWidth: "300px",
    minHeight: "75px",
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "none",
    lineHeight: "27px",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "#8BC677",
      fontSize: "16px",
    },
  },
  tabHorizontal: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "none",
    lineHeight: "27px",
    opacity: "1",
    "&:hover": {
      color: "#8BC677",
      fontSize: "16px",
    },
  },
}));

export default useStyles;
