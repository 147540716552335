import React from "react";
import useStyles from "./GrowthStory-jss";
import PropTypes from "prop-types";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Avatar,
  Divider,
  Grid,
} from "@material-ui/core";
import stories from "./stories";

import {
  EVENT_CATEGORIES,
  EVENT_ACTIONS,
  EVENT_LABELS,
} from "../../../constants";
import { GAnalyticsPostEvent } from "../../../utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const nameParser = (name) =>
  name.toLowerCase().replaceAll(" ", "_").replaceAll(".", "");

export default function GrowthStory() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <p className={classes.header}>Growth Stories</p>

      {stories.map((person, index) => (
        <div key={index}>
          <TabPanel value={value} index={index}>
            <div className={classes.panel}>
              <h1>{person.name}</h1>
              <h5>{person.role}</h5>
              {window.innerWidth > 900 ? (
                <Grid container spacing={1} className={classes.largeContainer}>
                  <Grid
                    item
                    sm={8}
                    xs={12}
                    className={classes.portraitContainer}
                  >
                    <p>{person.story}</p>
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    xs={12}
                    className={classes.portraitContainer}
                  >
                    <img
                      src={person.tallImage}
                      className={classes.portrait}
                      alt="person"
                    />
                  </Grid>
                </Grid>
              ) : (
                <div className={classes.resizedContainer}>
                  <p>{person.story}</p>
                  <div className={classes.resizedPortrait}>
                    <img src={person.tallImage} alt="person"></img>
                  </div>
                </div>
              )}
            </div>
          </TabPanel>
        </div>
      ))}

      <AppBar
        position="static"
        color="transparent"
        className={classes.appBar}
        elevation={0}
      >
        <Divider />
        <Tabs
          selectionFollowsFocus
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {stories.map((person, index) => {
            const isCurrentTabSelected = index === value;
            return (
              <Tab
                key={index}
                className={
                  isCurrentTabSelected ? classes.tabSelected : classes.tab
                }
                label={
                  <div className={classes.tabName}>
                    <p
                      className={
                        isCurrentTabSelected
                          ? classes.smallNameSelected
                          : classes.smallName
                      }
                    >
                      {person.name}
                    </p>
                    <p
                      className={
                        isCurrentTabSelected
                          ? classes.labelSubtextSelected
                          : classes.labelSubtext
                      }
                    >
                      {person.status}
                    </p>
                  </div>
                }
                {...a11yProps({ index })}
                icon={
                  <Avatar
                    alt="test avatar"
                    src={person.thumbnailImage}
                    className={classes.thumbnailPic}
                    variant="square"
                  />
                }
                onClick={() =>
                  GAnalyticsPostEvent(
                    EVENT_CATEGORIES.LANDING_PAGE_IMPACT,
                    EVENT_ACTIONS.CLICK,
                    `${EVENT_LABELS.GROWTH_STORIES_TAB}_${nameParser(
                      person.name
                    )}`
                  )
                }
              />
            );
          })}
        </Tabs>
      </AppBar>
    </div>
  );
}
