import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5% 10%",
    background: "rgba(197, 230, 186, 0.22)",
  },
  title: {
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "72px",
    left: "15px",
  },
  header: {
    paddingTop: "48px",
    paddingBottom: "15px",
    fontWeight: "500",
    fontSize: "24px",
  },
  panel: {
    "& .MuiBox-root": {
      padding: "0px",
    },
    paddingTop: "14px",
  },
  tab: {
    paddingLeft: "0",
  },
}));

export default useStyles;
