import React from "react";
import PastEvents from "./PastEvents";
import Programs from "./Programs";

export default function Initiatives_landing() {
  return (
    <div>
      <PastEvents />
      <Programs />
    </div>
  );
}
