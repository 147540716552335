import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    borderRadius: 0,
    margin: "10px",
    marginBottom: "0px",
    height: "90%",
  },
  details: {
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  content: {
    borderLeft: "solid #fff",
    margin: "10%",
    padding: 0,
    paddingLeft: "10%",
    marginBottom: "5%",
  },
  image: {
    width: "100%",
  },
  bigText: {
    fontWeight: "600",
    fontSize: "3vw",
  },
  smallText: {
    fontWeight: "600",
    fontSize: "1.5vw",
  },
}));

export default useStyles;
