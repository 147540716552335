import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "../../../utils/store";
import useStyles from "./Profile-jss";
import PropTypes from "prop-types";
import ProfileForm from "./ProfileForm";
import ProfilePictureUploader from "./ProfilePictureUploader";
import { Box, Button, Typography, Avatar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
// background images
import topRightLeaf from "../../../assets/images/portal/profileForm/toprightleaf.svg";
import bottomLeftLeaf from "../../../assets/images/portal/profileForm/bottomleftleaf.svg";
import bottomRightLeaf from "../../../assets/images/portal/profileForm/bottomrightleaf.svg";
import topLeftLeaf from "../../../assets/images/portal/profileForm/topleftleaf.svg";
import Smile from "../../../assets/images/portal/mentorProfile/smile.png";

import theme from "src/components/theme";
import { useAuth0 } from "@auth0/auth0-react";

Profile.prototype = {
  name: PropTypes.string,
  year: PropTypes.string,
  pronouns: PropTypes.string,
  email: PropTypes.string,
};

export default function Profile(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [readMode, setReadMode] = useState(true);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [image, setImage] = useState();
  const classes = useStyles();
  const profileImageDataUrl = useStore((s) => s.profileImageDataUrl);
  const setProfileImageDataUrl = useStore((s) => s.setProfileImageDataUrl);
  const setProfileImageChanged = useStore((s) => s.setProfileImageChanged);
  const imageKey = useStore((s) => s.imageKey);

  const getProfileImage = useCallback(
    async (imageKey) => {
      const token = await getAccessTokenSilently();
      setProfileImageDataUrl("");
      setImage("");

      if (!imageKey) {
        return;
      }

      const response = await fetch(
        `${
          process.env.REACT_APP_TECHPLUS_API
        }/profile_images/?image_key=${encodeURIComponent(imageKey)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const blob = await response.blob();
      setImage(URL.createObjectURL(blob));
    },
    [getAccessTokenSilently, setProfileImageDataUrl]
  );

  function toggleReadEdit() {
    setReadMode(!readMode);
  }
  const handleUploadOpen = () => {
    setUploadOpen(true);
  };
  const handleUploadClose = () => {
    setUploadOpen(false);
  };

  const removeImage = () => {
    setProfileImageDataUrl("");
    setImage("");
    setProfileImageChanged(true);
  };

  useEffect(() => {
    getProfileImage(imageKey);
  }, [getProfileImage, imageKey]);

  return (
    <div className={classes.root}>
      <Box display={{ xs: "none", sm: "block" }}>
        <img
          src={topRightLeaf}
          alt="leaf"
          className={classes.topRightLeafStyle}
        />
        <img
          src={bottomLeftLeaf}
          alt="leaf"
          className={classes.bottomLeftLeafStyle}
        />
        <img
          src={bottomRightLeaf}
          alt="leaf"
          className={classes.bottomRightLeafStyle}
        />
        <img
          src={topLeftLeaf}
          alt="leaf"
          className={classes.topLeftLeafStyle}
        />
      </Box>
      <Typography className={classes.title}>Your Profile</Typography>
      {readMode && (
        <Grid container justify="flex-end">
          <Button
            className={classes.button}
            variant="contained"
            color={theme.palette.secondaryGreenDark.main}
            size="large"
            onClick={toggleReadEdit}
          >
            Edit
          </Button>
        </Grid>
      )}
      <Grid container spacing={1} className={classes.mainArea}>
        <Grid
          item
          xs={12}
          sm={3}
          align="center"
          className={classes.photoSection}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Avatar
              src={
                profileImageDataUrl && profileImageDataUrl !== ""
                  ? profileImageDataUrl
                  : image && image !== ""
                  ? image
                  : Smile
              }
              className={classes.avatar}
            />
            {!readMode && (
              <>
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={handleUploadOpen}
                >
                  Upload Image
                </Button>
                {profileImageDataUrl || imageKey ? (
                  <Button
                    variant="text"
                    className={classes.removeImageLink}
                    onClick={removeImage}
                  >
                    Remove Image
                  </Button>
                ) : null}
              </>
            )}
          </Grid>
        </Grid>
        <Grid container xs={12} sm={9} spacing={1} className={classes.editArea}>
          <Grid item>
            <ProfileForm
              readMode={readMode}
              onChange={toggleReadEdit}
            ></ProfileForm>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={uploadOpen} onclose={handleUploadClose} maxWidth="md">
        <ProfilePictureUploader />
        <Button variant="outlined" onClick={handleUploadClose}>
          {profileImageDataUrl ? "Save" : "Cancel"}
        </Button>
      </Dialog>
    </div>
  );
}
